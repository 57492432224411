<template>
  <div
    class="input"
    :class="{
      'input--borderless': !hasBorder,
      'input--error': hasError,
      'input--large': large,
      'input--visible-label': !hideLabel,
    }"
  >
    <label
      :for="id"
      class="input__label"
      :class="{
        'input__label--hidden': hideLabel,
        'input__label--read-only': readOnly,
        'input__label--title': labelAsTitle,
      }"
      v-bind="$attrs"
      >{{ label }}</label
    >
    <template v-if="readOnly">
      <div class="input__value">{{ internalValue }}</div>
    </template>
    <template v-else>
      <div class="input__field-container">
        <input
          :id="id"
          :class="{
            'input__field--center': centerText,
            'input__field--round': round,
            'input__field--suffix': suffix,
          }"
          :type="type"
          :value="internalValue"
          class="input__field"
          v-bind="$attrs"
          v-on="$listeners"
          @input="internalValue = $event.target.value"
        />
        <transition mode="out-in" name="fade">
          <svg
            v-if="type === 'search' && !loading && internalValue === ''"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            class="input__search"
          >
            <defs />
            <circle cx="11" cy="11" r="8" />
            <path d="M21 21l-4.35-4.35" />
          </svg>
          <svg
            v-else-if="type === 'search' && !loading && internalValue !== ''"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            class="input__search input__search--clickable"
            @click="clearValue"
          >
            <defs />
            <path d="M18 6L6 18M6 6l12 12" />
          </svg>
        </transition>
        <div v-if="suffix" class="input__suffix">
          {{ suffix }}
        </div>
      </div>
    </template>
    <Error :message="errorMessage" :visible="hasError" />
    <Loader class="input__loader" :light="lightLoader" :visible="loading" />
    <router-link v-if="link" :to="link" class="input__link" />
  </div>
</template>

<script>
import cloneDeep from "lodash.clonedeep";
import Error from "@/components/Error";
import Loader from "@/components/Loader";
import { uuid } from "@/helpers/uuid";

export default {
  name: "Input",
  components: {
    Error,
    Loader,
  },
  inheritAttrs: false,
  model: {
    event: "input",
  },
  props: {
    centerText: {
      default: false,
      type: Boolean,
    },
    errorMessage: {
      default: "",
      type: String,
    },
    hasBorder: {
      default: true,
      type: Boolean,
    },
    hasError: {
      default: false,
      type: Boolean,
    },
    hideLabel: {
      default: false,
      type: Boolean,
    },
    label: {
      required: true,
      type: String,
    },
    labelAsTitle: {
      default: false,
      type: Boolean,
    },
    large: {
      default: false,
      type: Boolean,
    },
    lightLoader: {
      default: false,
      type: Boolean,
    },
    link: {
      default: null,
      type: Object,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    readOnly: {
      default: false,
      type: Boolean,
    },
    round: {
      default: false,
      type: Boolean,
    },
    suffix: {
      default: "",
      type: String,
    },
    type: {
      required: true,
      type: String,
    },
    value: {
      default: null,
      validator: prop => {
        if (prop === null) return true;
        return (
          typeof prop === "number" ||
          typeof prop === "object" ||
          typeof prop === "string"
        );
      },
    },
  },
  data() {
    const value = cloneDeep(this.value);

    return {
      id: uuid(),
      initialValue: value,
      internalValue: value,
    };
  },
  watch: {
    internalValue(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue, oldValue) {
      if (typeof newValue === "object")
        newValue = newValue?.data ? oldValue + newValue.data : oldValue;

      if (newValue === oldValue) return;

      this.internalValue =
        newValue === 0
          ? newValue
          : newValue || (this.initialValue === null ? this.initialValue : "");
    },
  },
  methods: {
    clearValue() {
      this.$emit("input", "");
    },
  },
};
</script>

<style lang="scss" scoped>
$search-icon-size: 1.5rem;

.input {
  position: relative;
  transition: padding 300ms ease;
  width: 100%;
  z-index: 1;

  .editable-product-sizes &,
  .price-matrix &,
  .static-product-sizes & {
    height: 100%;
    width: 100%;
  }

  &--error {
    padding-bottom: var(--spacing-error-message);
  }

  &__field {
    appearance: none;
    background-color: var(--color-input-background);
    border: 1px solid var(--color-input-border);
    border-radius: 0.25rem;
    font-size: 1rem;
    height: 100%;
    margin: 0;
    padding: var(--spacing-input-field);
    position: relative;
    transition: background-color 300ms ease, border-color 300ms ease;
    width: 100%;
    z-index: 2;

    &:disabled {
      background-color: var(--color-input-background-disabled) !important;
      border-color: transparent !important;
      cursor: text;

      .price-matrix & {
        border-color: var(--color-input-border) !important;
        border-top-color: transparent !important;
      }

      .price-matrix__size:not(:first-child) & {
        border-left-color: transparent !important;
      }

      .editable-product-sizes &,
      .products &,
      .static-product-sizes & {
        background-color: transparent !important;
      }
    }

    &:focus {
      z-index: 3;
    }

    &:focus,
    &:hover {
      background-color: var(--color-input-background-active);
      border-color: var(--color-input-border-active);
      outline: none;
    }

    &::placeholder {
      opacity: 0.5;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:-ms-input-placeholder {
      opacity: 0.5;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &::-ms-input-placeholder {
      opacity: 0.5;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &[type="number"] {
      -moz-appearance: textfield;
    }

    &[type="number"]::-webkit-inner-spin-button,
    &[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }

    &[type="search"] {
      padding-right: $search-icon-size * 2 !important;

      &::-ms-clear,
      &::-ms-reveal {
        display: none;
        height: 0;
        width: 0;
      }

      &::-webkit-search-cancel-button,
      &::-webkit-search-decoration,
      &::-webkit-search-results-button,
      &::-webkit-search-results-decoration {
        display: none;
      }
    }

    &[type="time"]::-webkit-calendar-picker-indicator {
      height: 1rem;
      width: 1rem;
    }

    &[type="time"]::-webkit-calendar-picker-indicator,
    &[type="time"]::-webkit-datetime-edit-fields-wrapper,
    &[type="time"]::-webkit-datetime-edit-hour-field {
      padding-bottom: 0;
      padding-top: 0;
    }

    &--center {
      text-align: center;
    }

    &--round {
      border-radius: 2rem;
      padding-left: calc(var(--spacing-input-field) * (1 + 1 / 3));
    }

    &--suffix {
      padding-right: 3.4375rem;

      .catalogues .basket__actions &,
      .catalogues .basket__entry & {
        padding-right: 2rem;
      }
    }

    .actions & {
      background-color: rgba(255, 255, 255, 0.15) !important;
      border-color: rgba(255, 255, 255, 0.3) !important;
      color: var(--color-table-actions-text) !important;
      font-size: 0.9375rem;
      width: 13.125rem;

      &::placeholder {
        color: var(--color-table-actions-text);
        opacity: 0.75;
      }

      &:-ms-input-placeholder {
        color: var(--color-table-actions-text);
        opacity: 0.75;
      }

      &::-ms-input-placeholder {
        color: var(--color-table-actions-text);
        opacity: 0.75;
      }
    }

    .editable-product-sizes &,
    .static-product-sizes & {
      background-color: transparent !important;
      color: inherit;
      height: 100%;
      padding-bottom: 0 !important;
      padding-top: 0 !important;
    }

    .header .block--search & {
      background-color: var(--color-header-input-background);
      border: none !important;
      box-shadow: none !important;
      color: var(--color-header-text);
      font-size: 0.875rem;
      font-weight: 400;

      @media (min-width: 64rem) {
        width: 10rem;
        transition: width 300ms ease 300ms;
      }

      @media (min-width: 80rem) {
        width: 12.5rem;
      }

      &:focus {
        @media (min-width: 64rem) {
          transition: width 300ms ease 0ms;
          width: 15rem;
        }

        @media (min-width: 80rem) {
          width: 17.5rem;
        }
      }

      &::placeholder {
        color: var(--color-header-text);
        opacity: 0.35;
      }

      &:-ms-input-placeholder {
        color: var(--color-header-text);
        opacity: 0.85;
      }

      &::-ms-input-placeholder {
        color: var(--color-header-text);
        opacity: 0.85;
      }
    }

    .input--borderless & {
      border: none !important;
      box-shadow: none !important;
      border-radius: 0;
      padding: 0.875rem 0.75rem;
    }

    .input--error & {
      border-color: var(--color-error);
    }

    .input--large & {
      @media (min-width: 48rem) {
        font-size: 1.15rem;
        padding: 1.25rem;
      }

      @media (max-height: 60rem) {
        font-size: 1rem;
        padding: 1rem;
      }
    }

    .price-matrix & {
      border-radius: 0;
      border-top-color: transparent;
      height: 100%;
      font-size: 0.9735rem;
      padding: var(--spacing-input-field) calc(var(--spacing-input-field) * 0.5);
      text-align: center;

      &:focus {
        border-color: var(--color-input-border-active) !important;
      }

      &:hover {
        border-color: var(--color-input-border);
        border-top-color: transparent;
      }
    }

    .price-matrix__size:not(:first-child) & {
      border-left-color: transparent;
    }

    .product__employees & {
      font-size: 0.875rem;
    }

    .products & {
      background-color: transparent;
    }

    .static-product-sizes & {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-container {
      background-color: var(--color-input-background);
      position: relative;
      z-index: 2;

      .actions &,
      .editable-product-sizes &,
      .price-matrix &,
      .static-product-sizes & {
        background-color: transparent;
        height: 100%;
      }
    }
  }

  &__label {
    cursor: pointer;
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 700;
    margin-bottom: 0.5rem;

    &[required] {
      &::after {
        content: "*";
        margin-left: 0.125rem;
      }
    }

    &--hidden {
      @include screen-reader-only();
    }

    &--read-only {
      cursor: default;
      font-weight: 700;
      margin-bottom: 0.125rem;

      &::after {
        display: none;
      }
    }

    &--title {
      font-size: 1.25rem;
    }
  }

  &__link {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 99;
  }

  &__loader {
    left: auto;
    margin-left: 0;
    right: $search-icon-size * 0.5;
    z-index: 2;

    .input--round & {
      right: $search-icon-size * 0.75;
    }
  }

  &__search {
    fill-rule: evenodd;
    fill: none;
    height: $search-icon-size;
    pointer-events: none;
    position: absolute;
    right: $search-icon-size * 0.5;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: var(--color-input-border-active);
    top: 50%;
    transform: translateY(-50%);
    width: $search-icon-size;
    z-index: 3;

    .header & {
      height: calc(var(--size-header-action-icon) * 0.8);
      opacity: 0.85;
      stroke: var(--color-header-text);
      stroke-width: 2;
      width: calc(var(--size-header-action-icon) * 0.8);
    }

    .input--large & {
      @media (min-width: 48rem) {
        height: $search-icon-size * 1.25;
        width: $search-icon-size * 1.25;
      }
    }

    .input--round & {
      right: $search-icon-size * 0.75;
    }

    &--clickable {
      cursor: pointer;
      pointer-events: auto;
    }
  }

  &__suffix {
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.875rem;
    position: absolute;
    right: 0.875rem;
    text-transform: uppercase;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;

    .actions & {
      color: var(--color-table-actions-text);
    }
  }

  &__value {
    font-size: 0.9375rem;
    min-height: 1.125rem;
    word-break: break-word;
  }
}
</style>
