<template>
  <FocusTrap v-model="isOpen" :escape-deactivates="false">
    <transition
      name="fade"
      appear=""
      @enter="isBoxVisible = true"
      @after-leave="$emit('close')"
    >
      <div
        v-if="isOpen"
        class="sidebar"
        role="dialog"
        :class="{ 'sidebar--right': right, 'sidebar--wide': wide }"
      >
        <transition
          :name="right ? 'slide-in-from-right' : 'slide-in-from-left'"
          @leave="isOpen = false"
        >
          <div v-if="isBoxVisible" class="sidebar__box" tabindex="-1">
            <div class="sidebar__header">
              <h2 class="sidebar__header-title">{{ title }}</h2>
              <button
                class="sidebar__header-button"
                :aria-label="getDictionaryEntry('Common.Close')"
                @click="handleClose"
              >
                <svg
                  v-if="right"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  class="sidebar__header-icon"
                >
                  <defs />
                  <path d="M5 12h14M12 5l7 7-7 7" />
                </svg>
                <svg
                  v-else
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  class="sidebar__header-icon"
                >
                  <defs />
                  <path d="M19 12H5M12 19l-7-7 7-7" />
                </svg>
              </button>
            </div>
            <div v-if="hasBodySlot" class="sidebar__body">
              <slot name="body" />
            </div>
            <div v-if="hasFooterSlot" class="sidebar__footer">
              <slot name="footer" />
            </div>
          </div>
        </transition>
        <div class="sidebar__underlay" @click="handleClose"></div>
      </div>
    </transition>
  </FocusTrap>
</template>

<script>
import { FocusTrap } from "focus-trap-vue";

export default {
  name: "Sidebar",
  components: {
    FocusTrap,
  },
  props: {
    right: {
      default: false,
      type: Boolean,
    },
    title: {
      default: "",
      type: String,
    },
    visible: {
      default: false,
      type: Boolean,
    },
    wide: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      isBoxVisible: false,
      isOpen: false,
    };
  },
  computed: {
    hasBodySlot() {
      return !!this.$slots.body;
    },
    hasFooterSlot() {
      return !!this.$slots.footer;
    },
  },
  watch: {
    visible(newValue) {
      this.isOpen = newValue;

      const scrollableElements = document.querySelectorAll("html, body, main");
      scrollableElements.forEach(el =>
        this.isOpen
          ? el.classList.add("sidebar-open")
          : el.classList.remove("sidebar-open"),
      );
    },
  },
  created() {
    const handleEscape = event => {
      if (this.isOpen && event.keyCode === 27) {
        this.handleClose();
      }
    };

    document.addEventListener("keydown", handleEscape);

    this.$once("hook:destroyed", () => {
      document.removeEventListener("keydown", handleEscape);
    });

    this.isOpen = this.visible;
  },
  methods: {
    handleClose() {
      this.isBoxVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
$sidebar-width: 32.5rem;
$sidebar-width-wide: 43rem;

.sidebar {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  will-change: transform;
  z-index: 97;

  &__body {
    flex-grow: 1;
    overflow-y: auto;
  }

  &__box {
    background-color: var(--color-sidebar-background);
    bottom: 0;
    display: flex;
    flex-direction: column;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;

    @media (min-width: $sidebar-width) {
      box-shadow: var(--elevation-64);
      max-width: $sidebar-width;
      right: auto;
      width: 100%;

      .sidebar--right & {
        left: auto;
        right: 0;
      }

      .sidebar--wide & {
        box-shadow: none;
        max-width: none;
        right: 0;
      }
    }

    @media (min-width: $sidebar-width-wide) {
      .sidebar--right & {
        left: auto;
        right: 0;
      }

      .sidebar--wide & {
        box-shadow: var(--elevation-64);
        max-width: $sidebar-width-wide;
        width: 100%;
      }
    }

    &:focus {
      outline: none;
    }
  }

  &__footer {
    border-top: 1px solid var(--color-sidebar-border);
    display: flex;
    flex-shrink: 0;
    justify-content: flex-end;
    padding: var(--spacing-sidebar);

    @media (min-width: 64rem) {
      padding: var(--spacing-sidebar-large);
    }

    .catalogues & {
      justify-content: space-between;
      opacity: 1;
      transition: visibility 0ms linear 0ms, opacity 300ms;
      visibility: visible;
    }

    .catalogues--step-1 &,
    .catalogues--step-5 &,
    .catalogues--step-6 & {
      opacity: 0;
      transition: visibility 0ms linear 300ms, opacity 300ms;
      visibility: hidden;
    }

    .quick-basket & {
      display: block;
      padding: 0 !important;
    }
  }

  &__header {
    align-items: center;
    border-bottom: 1px solid var(--color-sidebar-border);
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    padding: var(--spacing-sidebar);
    position: relative;

    @media (min-width: 64rem) {
      padding: var(--spacing-sidebar-large);
    }

    &-button {
      @include reset-button();
    }

    &-button,
    &-icon {
      height: var(--size-sidebar-icon);
      width: var(--size-sidebar-icon);

      @media (min-width: 64rem) {
        height: var(--size-sidebar-icon-large);
        width: var(--size-sidebar-icon-large);
      }
    }

    &-icon {
      fill-rule: evenodd;
      fill: none;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke: var(--color-text-primary);
    }

    &-title {
      font-size: 1.25em;
      margin: 0 var(--size-sidebar-icon) 0 0;

      @media (min-width: 64rem) {
        font-size: 1.5em;
      }
    }
  }

  &__underlay {
    background-color: rgba(0, 0, 0, 0.25);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
}
</style>
