import { render, staticRenderFns } from "./StockIndicatorForBulk.vue?vue&type=template&id=675acaa6&scoped=true"
import script from "./StockIndicatorForBulk.vue?vue&type=script&lang=js"
export * from "./StockIndicatorForBulk.vue?vue&type=script&lang=js"
import style0 from "./StockIndicatorForBulk.vue?vue&type=style&index=0&id=675acaa6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "675acaa6",
  null
  
)

export default component.exports