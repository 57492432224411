import { render, staticRenderFns } from "./BasketLineProductImage.vue?vue&type=template&id=df44a082&scoped=true"
import script from "./BasketLineProductImage.vue?vue&type=script&lang=js"
export * from "./BasketLineProductImage.vue?vue&type=script&lang=js"
import style0 from "./BasketLineProductImage.vue?vue&type=style&index=0&id=df44a082&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df44a082",
  null
  
)

export default component.exports