import { mapGetters } from "vuex";

const currencySpecialCases = ["GBP", "USD"];

export default {
  computed: {
    ...mapGetters("authentication", ["userConfiguration"]),
  },
  methods: {
    convertNumberToLocaleString(number) {
      const { userConfiguration } = this;

      if (!Number.isFinite(number)) {
        console.error("A number has to be provided.");
        return number;
      }

      let locale;

      switch (userConfiguration.contextSiteLanguage) {
        case "da":
          locale = "da-DK";
          break;
        case "de":
          locale = "de-DE";
          break;
        case "en":
          locale = "en-GB";
          break;
        case "es":
          locale = "es-ES";
          break;
        case "fr":
          locale = "fr-FR";
          break;
        case "nb":
          locale = "nb-NO";
          break;
        case "nl":
          locale = "nl-NL";
          break;
        default:
          locale = undefined;
          break;
      }

      return number.toLocaleString(locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    convertPriceStringToFloat(string) {
      const { userConfiguration } = this;

      if (typeof string !== "string") {
        console.error("A string has to be provided.");
        return string;
      }

      let stringWithConvertedSeparators;

      switch (userConfiguration.contextSiteLanguage) {
        case "en":
          stringWithConvertedSeparators = string.replace(/,/g, "");
          break;
        default:
          stringWithConvertedSeparators = string
            .replace(/\./g, "")
            .replace(/\,/g, ".");
          break;
      }

      const stringWithoutSpaces = stringWithConvertedSeparators.replace(
        /\s/g,
        "",
      );

      return parseFloat(stringWithoutSpaces);
    },
    normalizePrice(value) {
      if (!value) {
        console.log("A value need to be provided.");
        return value;
      }

      if (typeof value === "string" || value.includes()) {
        value = value.replace(/,/g, ".");
      }

      return value;
    },

    /**
     * Will transform the price based on the rules defined by F-Engel
     * For all the cases should be something like: 1.394,40 EUR
     * If Pounds (GBP) or Dollars (USD) should be something like: 1,394.40 GBP
     * IF Points (PT) should be something like: 1394 PT
     * @param value
     * @return {string}
     */
    formatPriceCurrency(value) {
      const { contextCurrency } = this.userConfiguration;

      // this means that the currency is Points and this logic is not applied
      if (contextCurrency === "PT") {
        return value;
      }

      if (typeof value === "number") {
        value = value.toFixed(2);
      }

      if (typeof value !== "string") {
        console.error("A string has to be provided.");
        return value;
      }

      // check if the decimal is a dot;
      const isDecimalSeparatorADot = this.hasDecimalSeparatorAsDot(value);
      // yes, this is a hack, but it works for now, because all the
      // calculations and currency format should be done in the BE
      const indexOfThousandsSeparator = this.getThousandsSeparatorIndex(value);

      // probably we need to handle the billions and millions case,
      // but let's see if that is a real case
      return value.replace(/[.,]/g, match => {
        if (currencySpecialCases.includes(contextCurrency)) {
          if (match === "." || !isDecimalSeparatorADot) {
            return indexOfThousandsSeparator !== -1 && match === "."
              ? ","
              : ".";
          }
        } else if (isDecimalSeparatorADot) {
          return indexOfThousandsSeparator !== -1 && match === "," ? "." : ",";
        }
        return match;
      });
    },
    hasDecimalSeparatorAsDot(str) {
      return str.length >= 3 && str[str.length - 3] === ".";
    },
    getThousandsSeparatorIndex(str) {
      return str.length >= 7 ? str.indexOf(str[str.length - 7]) : -1;
    },
  },
};
