<template>
  <div class="quick-buy">
    <Modal
      :title="
        currentProduct && currentVariant
          ? `${currentProduct.name} (${currentVariant.id})`
          : ''
      "
      :visible="true"
      @close="handleClose"
    >
      <template v-if="isUserDealer" #header>
        <CustomerSelector />
      </template>
      <template #body>
        <DynamicData :loading="isLoading" transition="fade">
          <Stack v-if="currentVariant && currentVariant.minPrice">
            <Price
              :price="currentVariant.minPrice"
              :show-personal-label="true"
            />
          </Stack>
          <Stack
            v-if="sizesForList && sizesForList.length"
            :one-and-half="true"
          >
            <div class="quick-buy__size">
              {{ getDictionaryEntry("Common.Labels.Size") }}:
              <span class="quick-buy__size-chart">{{
                getPreferredSizeChartLabel(sizesForList[0])
              }}</span>
            </div>
          </Stack>
          <Stack :half="true">
            <SizeMatrix
              v-if="currentProduct && currentVariant"
              :close="handleClose"
              :size-groups="currentVariant.sizeGroups"
              class="quick-buy__matrix"
              @initial-size-groups-updated="updateInitialSizeGroups($event)"
              @size-groups-updated="updateSizeGroups($event)"
            />
          </Stack>
          <Stack class="quick-buy__list">
            <SizeList :sizes="sizesForList" />
          </Stack>
        </DynamicData>
      </template>
      <template #footer>
        <div>
          <router-link
            :to="{ name: 'checkout' }"
            class="quick-buy__checkout"
            @click.native="close"
            >{{ getDictionaryEntry("Checkout.GoToCheckout") }}
          </router-link>
        </div>
        <div class="quick-buy__update-buttons">
          <Button
            :disabled="!hasQuantityChanged"
            :limited-width="true"
            :round="true"
            :secondary="true"
            :show-loader="isUpdatingBasket"
            class="quick-buy__update"
            @click="updateBasket(false)"
            >{{ getDictionaryEntry("Common.UpdateBasket") }}</Button
          >
          <Button
            :disabled="!hasQuantityChanged"
            :limited-width="true"
            :round="true"
            :show-loader="isUpdatingBasket"
            class="quick-buy__update"
            @click="updateBasket(true)"
            >{{
              getDictionaryEntry("Common.UpdateBasketAndCloseModal")
            }}</Button
          >
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import differenceWith from "lodash.differencewith";
import isEqual from "lodash.isequal";
import { mapActions, mapGetters } from "vuex";
import Button from "@/components/Button";
import CustomerSelector from "@/components/CustomerSelector";
import DynamicData from "@/components/DynamicData";
import Modal from "@/components/Modal";
import Price from "@/components/Price";
import SizeList from "@/components/SizeList";
import SizeMatrix from "@/components/SizeMatrix";
import Stack from "@/components/Stack";
import { alertTypes } from "@/helpers/alertTypes";
import { errorAlert } from "@/helpers/errorAlert";
import { statuses } from "@/helpers/statuses";

export default {
  name: "QuickBuy",
  components: {
    Button,
    CustomerSelector,
    DynamicData,
    Modal,
    Price,
    SizeList,
    SizeMatrix,
    Stack,
  },
  data() {
    return {
      initialInternalSizeGroups: [],
      internalSizeGroups: [],
      isUpdatingBasket: false,
    };
  },
  computed: {
    ...mapGetters("authentication", ["customerId", "isUserDealer"]),
    ...mapGetters("products", [
      "currentProduct",
      "currentVariant",
      "statusOfProducts",
    ]),
    hasQuantityChanged() {
      return !isEqual(this.initialInternalSizeGroups, this.internalSizeGroups);
    },
    isLoading() {
      return this.statusOfProducts === statuses.LOADING;
    },
    sizesForList() {
      if (!this.currentVariant) return [];

      const { sizeGroups } = this.currentVariant;

      if (!sizeGroups || !sizeGroups.length) return [];

      return sizeGroups
        .map(sizeGroup => sizeGroup.sizes.map(size => size))
        .flat();
    },
  },
  created() {
    const {
      $route: {
        query: { mode, productId, variantId },
      },
      GET_PRODUCT,
      UPDATE_CURRENT_PRODUCT,
      UPDATE_CURRENT_VARIANT_ID,
      isUserDealer,
    } = this;

    if (mode && mode.includes("details")) return;

    UPDATE_CURRENT_PRODUCT({ product: {} });
    UPDATE_CURRENT_VARIANT_ID({ id: variantId });

    if (!isUserDealer) {
      GET_PRODUCT({ id: productId });
    }
  },
  destroyed() {
    const {
      $route: {
        query: { mode },
      },
      UPDATE_CURRENT_PRODUCT,
    } = this;

    if (mode && mode.includes("details")) return;

    UPDATE_CURRENT_PRODUCT({ product: null });
  },
  methods: {
    ...mapActions("alerts", ["OPEN_ALERT"]),
    ...mapActions("basket", ["GET_BASKET", "UPDATE_BASKET"]),
    ...mapActions("products", [
      "GET_PRODUCT",
      "UPDATE_CURRENT_PRODUCT",
      "UPDATE_CURRENT_VARIANT_ID",
    ]),
    handleClose() {
      const {
        $route: { query },
        $router,
      } = this;

      if (query.mode && query.mode.includes("details")) {
        $router.push({
          query: {
            ...query,
            mode: "details",
            productId: query.productId,
            variantId: query.variantId,
          },
        });
        return;
      }

      const {
        mode, //eslint-disable-line
        customerId, //eslint-disable-line
        productId, //eslint-disable-line
        variantId, //eslint-disable-line
        ...remainingQueryParams
      } = query;

      $router.push({ query: remainingQueryParams });
    },
    updateBasket(shouldCloseQuickBuyAfterUpdate = false) {
      const currentSizes = this.internalSizeGroups
        .map(group => group.sizes)
        .flat();
      const initialSizes = this.initialInternalSizeGroups
        .map(group => group.sizes)
        .flat();

      const changedSizes = differenceWith(currentSizes, initialSizes, isEqual);

      const changedSizesWithPreviousQuantity = changedSizes.map(
        ({ quantity, sku }) => {
          const matchingInitialSize = initialSizes.find(
            size => size.sku === sku,
          );

          return {
            previousQuantity: matchingInitialSize
              ? matchingInitialSize.quantity || 0
              : 0,
            quantity: quantity || 0,
            sku,
          };
        },
      );

      this.updateSize(
        0,
        changedSizesWithPreviousQuantity,
        shouldCloseQuickBuyAfterUpdate,
      );
    },
    updateInitialSizeGroups(value) {
      this.initialInternalSizeGroups = value;
    },
    updateSize(index, array, shouldCloseQuickBuyAfterUpdate = false) {
      this.isUpdatingBasket = true;

      this.UPDATE_BASKET({ ...array[index] })
        .then(() => {
          if (index < array.length - 1) {
            this.updateSize(index + 1, array, shouldCloseQuickBuyAfterUpdate);
            return;
          }

          this.isUpdatingBasket = false;
          this.GET_BASKET();

          if (shouldCloseQuickBuyAfterUpdate) {
            this.handleClose();
          }

          this.OPEN_ALERT({
            key: "SuccessMessage.BasketUpdated",
            type: alertTypes.SUCCESS,
          });
        })
        .catch(error => {
          this.isUpdatingBasket = false;
          errorAlert(error);
        });
    },
    updateSizeGroups(value) {
      this.internalSizeGroups = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.quick-buy {
  &__checkout {
    @include reset-button();

    align-items: center;
    background-color: var(--color-button-secondary-background);
    border: 1px solid var(--color-button-secondary-border);
    border-radius: 2rem;
    color: var(--color-button-secondary-text);
    display: flex;
    font-size: 0.875rem;
    font-weight: 500;
    justify-content: center;
    padding: var(--spacing-button);
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    transition: background-color 300ms ease, border-color 300ms ease,
      box-shadow 300ms ease, color 300ms ease, opacity 300ms ease;

    @media (min-width: 64rem) {
      display: none;
    }

    &:focus,
    &:hover {
      border-color: var(--color-button-secondary-border-active);
    }

    .checkout-view & {
      display: none;
    }
  }

  &__list {
    @media (min-width: 48rem) {
      display: none;
    }
  }

  &__matrix {
    display: none;

    @media (min-width: 48rem) {
      display: block;
    }
  }

  &__size {
    font-size: 1.125rem;
    font-weight: 700;

    &-chart {
      text-transform: uppercase;
    }
  }

  &__update {
    display: none;

    @media (min-width: 48rem) {
      display: block;
    }

    &-buttons {
      display: flex;
    }
  }
}
</style>
