import axios from "axios";
import cloneDeep from "lodash.clonedeep";
import { alertTypes } from "@/helpers/alertTypes";
import { endpoints } from "@/helpers/endpoints";
import { errorAlert } from "@/helpers/errorAlert";
import { statuses } from "@/helpers/statuses";

const state = () => ({
  allowedDivisionsForCurrentUser: [],
  currentUser: null,
  currentUserForCheckout: null,
  currentUserId: null,
  filters: {},
  isModalForCreatingSalespersonOpen: false,
  isModalForCreatingUserOpen: false,
  lastSearchedQuery: "",
  pagination: {},
  resetPasswordCode: {},
  selectedUsers: [],
  status: "",
  users: [],
});

const getters = {
  allowedDivisionsForCurrentUser: state => state.allowedDivisionsForCurrentUser,
  currentPage: state => {
    if (!state.pagination) return 0;

    return state.pagination.currentPage || 0;
  },
  currentUser: state => state.currentUser,
  currentUserForCheckout: state => state.currentUserForCheckout,
  currentUserId: state => state.currentUserId,
  filters: state => state.filters,
  isModalForCreatingSalespersonOpen: state =>
    state.isModalForCreatingSalespersonOpen,
  isModalForCreatingUserOpen: state => state.isModalForCreatingUserOpen,
  lastSearchedQuery: state => state.lastSearchedQuery,
  nameOfCurrentUser: state => {
    const { currentUser } = state;
    if (!currentUser) return null;

    return currentUser.name ? currentUser.name : null;
  },
  nameOfCurrentUserForCheckout: state => {
    const { currentUserForCheckout } = state;
    if (!currentUserForCheckout) return null;

    return currentUserForCheckout.name ? currentUserForCheckout.name : null;
  },
  pagination: state => state.pagination,
  resetPasswordCode: state => state.resetPasswordCode,
  selectedUsers: state => state.selectedUsers,
  statusOfUsers: state => state.status,
  totalNumberOfUsers: state => {
    if (!state.pagination) return 0;

    return state.pagination.totalResults;
  },
  users: state => state.users,
  usersOfCustomer: state => id =>
    state.users.filter(user => user.customerId === id),
};

const actions = {
  ACTIVATE_PRE_ORDER_MODE({ commit, dispatch, rootGetters }) {
    commit("SET_USERS_STATUS", { status: statuses.LOADING });

    const userId = rootGetters["authentication/userId"];

    return axios
      .post(`${endpoints.ECOMMERCE}/users/${userId}/preorderstate`)
      .then(() => {
        commit("SET_USERS_STATUS", { status: statuses.SUCCESS });
        dispatch("basket/GET_BASKET", null, { root: true });
        dispatch(
          "authentication/UPDATE_PRE_ORDER_STATE",
          { value: true },
          { root: true },
        );
      })
      .catch(error => {
        commit("SET_USERS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  ADD_USER_WITH_ALREADY_USED_IDENTIFIER_TO_CUSTOMER(
    { commit, dispatch, rootGetters },
    payload,
  ) {
    const customerId = rootGetters["customers/currentCustomerId"];

    commit("SET_USERS_STATUS", { status: statuses.SAVING });

    return new Promise((resolve, reject) => {
      axios
        .post(
          `${endpoints.ECOMMERCE}/users/${payload.userId}/add-to-existing/${customerId}`,
          {
            ...payload.user,
          },
        )
        .then(() => {
          commit("SET_USERS_STATUS", { status: statuses.SUCCESS });
          dispatch("CLOSE_MODAL_FOR_CREATING_USER");
          resolve();
        })
        .catch(error => {
          commit("SET_USERS_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject(error);
        });
    });
  },
  CLOSE_MODAL_FOR_CREATING_USER({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_CREATING_USER", { value: false });
  },
  CREATE_USER({ commit, dispatch }, payload) {
    commit("SET_USERS_STATUS", { status: statuses.SAVING });

    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoints.ECOMMERCE}/users`, {
          ...payload.user,
        })
        .then(({ data }) => {
          commit("SET_USERS_STATUS", { status: statuses.SUCCESS });
          dispatch("CLOSE_MODAL_FOR_CREATING_USER");
          resolve(data);
        })
        .catch(error => {
          commit("SET_USERS_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject(error);
        });
    });
  },
  DEACTIVATE_PRE_ORDER_MODE({ commit, dispatch, rootGetters }) {
    commit("SET_USERS_STATUS", { status: statuses.LOADING });

    const userId = rootGetters["authentication/userId"];

    return axios
      .delete(`${endpoints.ECOMMERCE}/users/${userId}/preorderstate`)
      .then(() => {
        commit("SET_USERS_STATUS", { status: statuses.SUCCESS });
        dispatch("basket/GET_BASKET", null, { root: true });
        dispatch(
          "authentication/UPDATE_PRE_ORDER_STATE",
          { value: false },
          { root: true },
        );
      })
      .catch(error => {
        commit("SET_USERS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  DELETE_SELECTED_USERS({ commit, getters, rootGetters, state }) {
    commit("SET_USERS_STATUS", { status: statuses.LOADING });

    const customerId = rootGetters["customers/currentCustomerId"];

    return axios
      .post(
        `${endpoints.ECOMMERCE}/users/delete-multiple/customer/${customerId}`,
        {
          identifiers: state.selectedUsers.map(user => user.id),
        },
      )
      .then(() => {
        commit("SET_USERS_STATUS", { status: statuses.SUCCESS });
        debugger;

        const users = cloneDeep(getters.users).filter(
          user =>
            !getters.selectedUsers.some(
              selectedUser => selectedUser.id === user.id,
            ),
        );

        const pagination = cloneDeep(getters.pagination);
        pagination.totalResults -= getters.selectedUsers.length;
        pagination.totalPages = Math.ceil(
          pagination.totalResults / pagination.pageSize,
        );

        commit("DESELECT_USERS");
        commit("SET_PAGINATION", { pagination });
        commit("SET_USERS", { users });
      })
      .catch(error => {
        commit("SET_USERS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_ALLOWED_DIVISIONS_FOR_USER({ commit }, payload) {
    commit("SET_USERS_STATUS", { status: statuses.LOADING });

    return axios
      .get(`${endpoints.ECOMMERCE}/users/${payload.id}/allowed-division`)
      .then(({ data }) => {
        commit("SET_USERS_STATUS", { status: statuses.SUCCESS });
        commit("SET_ALLOWED_DIVISIONS_FOR_CURRENT_USER", { divisions: data });
      })
      .catch(error => {
        commit("SET_USERS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_RESET_PASSWORD_CODE({ commit }, payload) {
    return axios
      .get(
        `${endpoints.ECOMMERCE}/users/${payload.id}/generateResetPasswordCode`,
      )
      .then(({ data }) => {
        commit("SET_RESET_PASSWORD_CODE", { code: data });
      })
      .catch(error => {
        errorAlert(error);
      });
  },
  GET_USER({ commit, dispatch, rootGetters }, payload) {
    commit("SET_USERS_STATUS", { status: statuses.LOADING });

    const customerId = rootGetters["customers/currentCustomerId"];

    return new Promise((resolve, reject) => {
      axios
        .get(
          `${endpoints.ECOMMERCE}/users/${payload.userId}/customer/${customerId}`,
        )
        .then(({ data }) => {
          commit("SET_USERS_STATUS", { status: statuses.SUCCESS });
          commit("SET_USER", { user: data });
          commit("SET_CURRENT_USER", { user: data });
          dispatch("CLOSE_MODAL_FOR_CREATING_USER");
          resolve(data);
        })
        .catch(error => {
          commit("SET_USERS_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject(error);
        });
    });
  },
  GET_USER_FOR_CHECKOUT({ commit }, payload) {
    commit("SET_USERS_STATUS", { status: statuses.LOADING });

    return new Promise((resolve, reject) =>
      axios
        .get(`${endpoints.ECOMMERCE}/users/${payload.id}/checkoutDetail`)
        .then(({ data }) => {
          commit("SET_USERS_STATUS", { status: statuses.SUCCESS });

          commit("SET_USER", { user: data });
          commit("SET_CURRENT_USER_FOR_CHECKOUT", { userForCheckout: data });
          resolve(data);
        })
        .catch(error => {
          commit("SET_USERS_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject();
        }),
    );
  },
  GET_USERS_OF_CUSTOMER({ commit, getters, rootGetters }, payload) {
    const {
      booleanFilterParams = rootGetters["exports/booleanFilterParams"],
      customerId = rootGetters["customers/currentCustomerId"],
      facetFilterParams = rootGetters["exports/facetFilterParams"],
      pageNumberZeroBased = getters["currentPage"],
      pageSize = 20,
      searchPhrase = getters["lastSearchedQuery"],
    } = payload;

    commit("SET_USERS_STATUS", { status: statuses.LOADING });

    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoints.ECOMMERCE}/customers/${customerId}/users`, {
          booleanFilterParams,
          customerId,
          facetFilterParams,
          pageNumberZeroBased,
          pageSize,
          searchPhrase,
        })
        .then(({ data: { documents, filters, pagination } }) => {
          commit("SET_USERS_STATUS", { status: statuses.SUCCESS });
          commit("SET_FILTERS", { filters });
          commit("SET_LAST_SEARCHED_QUERY", { query: searchPhrase });
          commit("SET_PAGINATION", { pagination });
          commit("SET_USERS", { users: documents });
          resolve({ filters });
        })
        .catch(error => {
          commit("SET_USERS_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject();
        });
    });
  },
  OPEN_MODAL_FOR_CREATING_SYSTEMADMINISTRATOR({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_CREATING_SYSTEMADMINISTRATOR", {
      value: true,
    });
  },
  OPEN_MODAL_FOR_CREATING_USER({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_CREATING_USER", { value: true });
  },
  RESET_PASSWORD_CODE({ commit }) {
    commit("SET_RESET_PASSWORD_CODE", { code: {} });
  },
  TOGGLE_SYSTEMADMINISTRATOR({ commit }, payload) {
    commit("SET_SELECTED_SYSTEMADMINISTRATOR", payload);
  },
  TOGGLE_SYSTEMADMINISTRATORS({ commit }, payload) {
    commit("SET_SELECTED_SYSTEMADMINISTRATORS", payload);
  },
  TOGGLE_USER({ commit }, payload) {
    commit("SET_SELECTED_USER", payload);
  },
  TOGGLE_USERS({ commit }, payload) {
    commit("SET_SELECTED_USERS", payload);
  },
  UPDATE_CURRENT_SYSTEMADMINISTRATOR_ID({ commit }, payload) {
    commit("SET_CURRENT_SYSTEMADMINISTRATOR_ID", payload);
    commit("SET_SELECTED_SYSTEMADMINISTRATORS");
  },
  UPDATE_CURRENT_USER_ID({ commit }, payload) {
    commit("SET_CURRENT_USER_ID", payload);
    commit("SET_SELECTED_USERS");
  },
  UPDATE_ENABLED_STATE_OF_USER({ commit }, payload) {
    commit("SET_USERS_STATUS", { status: statuses.LOADING });

    return new Promise((resolve, reject) => {
      axios
        .post(
          `${endpoints.ECOMMERCE}/users/${payload.userId}/enable/customer/${payload.customerId}`,
          {
            enabled: payload.value,
          },
        )
        .then(() => {
          commit("SET_USERS_STATUS", { status: statuses.SUCCESS });
          resolve();
        })
        .catch(error => {
          commit("SET_USERS_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject();
        });
    });
  },
  UPDATE_PRINTED_NAME({ commit }, payload) {
    commit("SET_USERS_STATUS", { status: statuses.LOADING });

    return new Promise((resolve, reject) => {
      axios
        .post(
          `${endpoints.ECOMMERCE}/users/${payload.userId}/update-printedName`,
          {
            printedName: payload.name,
          },
        )
        .then(() => {
          commit("SET_USERS_STATUS", { status: statuses.SUCCESS });
          resolve();
        })
        .catch(error => {
          commit("SET_USERS_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject();
        });
    });
  },
  UPDATE_USER({ commit, dispatch, rootGetters }, payload) {
    commit("SET_USERS_STATUS", { status: statuses.SAVING });

    const contextUserId = rootGetters["authentication/userId"];
    const customerId = rootGetters["customers/currentCustomerId"];
    const { id, user } = payload;

    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoints.ECOMMERCE}/users/${id}/customer/${customerId}`, {
          ...user,
          languageCode: Array.isArray(user.languageCode)
            ? null
            : user.languageCode,
          sizeSystem: Array.isArray(user.sizeSystem) ? null : user.sizeSystem,
          timeZone: Array.isArray(user.timeZone) ? null : user.timeZone,
        })
        .then(({ data }) => {
          commit("SET_USERS_STATUS", { status: statuses.SUCCESS });
          commit("SET_USER", { user: data });
          commit("SET_CURRENT_USER", { user: data });
          dispatch(
            "alerts/OPEN_ALERT",
            {
              key: "Common.YourChangesWereSaved",
              type: alertTypes.SUCCESS,
            },
            {
              root: true,
            },
          );

          if (contextUserId === id) {
            dispatch(
              "authentication/GET_USER_CONFIGURATION",
              {},
              { root: true },
            );
          }

          resolve(user);
        })
        .catch(error => {
          commit("SET_USERS_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject(error);
        });
    });
  },
  WELCOME_SELECTED_USERS({ commit, dispatch, getters }) {
    commit("SET_USERS_STATUS", { status: statuses.LOADING });

    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoints.ECOMMERCE}/users/send-welcomemail`, {
          identifiers: getters.selectedUsers.map(user => user.id),
        })
        .then(response => {
          commit("SET_USERS_STATUS", { status: statuses.SUCCESS });

          const users = cloneDeep(getters.users);

          getters.selectedUsers.forEach(selectedUser => {
            const indexOfMatchingUser = users.findIndex(
              user => user.id === selectedUser.id,
            );

            if (indexOfMatchingUser === -1) return;

            users[indexOfMatchingUser].disableWelcomeMailButton = true;
            users[indexOfMatchingUser].welcomeMailSentLabel =
              "User.WelcomeMail.Sending";
          });

          commit("DESELECT_USERS");
          commit("SET_USERS", { users });
          dispatch(
            "alerts/OPEN_ALERT",
            {
              key: "Administration.WelcomeEmailsSuccessfullySent",
              type: alertTypes.SUCCESS,
            },
            {
              root: true,
            },
          );
          resolve(response);
        })
        .catch(error => {
          commit("SET_USERS_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject(error);
        });
    });
  },
};

const mutations = {
  DESELECT_SYSTEMADMINISTRATORS(state) {
    state.selectedSystemadministrators = [];
  },
  DESELECT_USERS(state) {
    state.selectedUsers = [];
  },
  SET_ALLOWED_DIVISIONS_FOR_CURRENT_USER(state, payload) {
    state.allowedDivisionsForCurrentUser = payload.divisions;
  },
  SET_CURRENT_SYSTEMADMINISTRATOR(state, payload) {
    state.currentSystemadministrator = payload.systemadministrator;
  },
  SET_CURRENT_SYSTEMADMINISTRATOR_ID(state, payload) {
    state.currentSystemadministratorId = payload.id;
  },
  SET_CURRENT_USER(state, payload) {
    state.currentUser = payload.user;
  },
  SET_CURRENT_USER_FOR_CHECKOUT(state, payload) {
    state.currentUserForCheckout = payload.userForCheckout;
  },
  SET_CURRENT_USER_ID(state, payload) {
    state.currentUserId = payload.id;
  },
  SET_FILTERS(state, payload) {
    state.filters = payload.filters;
  },
  SET_LAST_SEARCHED_QUERY(state, payload) {
    state.lastSearchedQuery = payload.query;
  },
  SET_PAGINATION(state, payload) {
    state.pagination = payload.pagination;
  },
  SET_RESET_PASSWORD_CODE(state, payload) {
    state.resetPasswordCode = payload.code;
  },
  SET_SELECTED_SYSTEMADMINISTRATOR(state, payload) {
    const { row: systemadministrator, value } = payload;

    if (value) {
      if (
        state.selectedSystemadministrators.some(
          entry => entry.id === systemadministrator.id,
        )
      ) {
        return;
      }

      state.selectedSystemadministrators.push(systemadministrator);
    } else {
      state.selectedSystemadministrators = state.selectedSystemadministrators.filter(
        selectedSystemadministrator =>
          selectedSystemadministrator.id !== systemadministrator.id,
      );
    }
  },
  SET_SELECTED_SYSTEMADMINISTRATORS(state, payload) {
    if (!payload) {
      state.selectedSystemadministrators = [];
      return;
    }

    const { rows: systemadministrators, value } = payload;

    if (value) {
      const identifiers = new Set(
        state.selectedSystemadministrators.map(
          selectedSystemadministrator => selectedSystemadministrator.id,
        ),
      );

      state.selectedSystemadministrators = [
        ...state.selectedSystemadministrators,
        ...systemadministrators
          .filter(systemadministrator => !systemadministrator.disableSelection)
          .filter(
            systemadministrator => !identifiers.has(systemadministrator.id),
          ),
      ];
    } else {
      state.selectedSystemadministrators = state.selectedSystemadministrators.filter(
        selectedSystemadministrator =>
          !systemadministrators.some(
            entry => entry.id === selectedSystemadministrator.id,
          ),
      );
    }
  },
  SET_SELECTED_USER(state, payload) {
    const { row: user, value } = payload;

    if (value) {
      if (state.selectedUsers.some(entry => entry.id === user.id)) {
        return;
      }

      state.selectedUsers.push(user);
    } else {
      state.selectedUsers = state.selectedUsers.filter(
        selectedUser => selectedUser.id !== user.id,
      );
    }
  },
  SET_SELECTED_USERS(state, payload) {
    if (!payload) {
      state.selectedUsers = [];
      return;
    }

    const { rows: users, value } = payload;

    if (value) {
      const identifiers = new Set(
        state.selectedUsers.map(selectedUser => selectedUser.id),
      );

      state.selectedUsers = [
        ...state.selectedUsers,
        ...users
          .filter(user => !user.disableSelection)
          .filter(user => !identifiers.has(user.id)),
      ];
    } else {
      state.selectedUsers = state.selectedUsers.filter(
        selectedUser => !users.some(entry => entry.id === selectedUser.id),
      );
    }
  },
  SET_SYSTEMADMINISTRATORS(state, payload) {
    state.systemadministrators = payload.systemadministrators;
  },
  SET_STATE_OF_MODAL_FOR_CREATING_SYSTEMADMINISTRATOR(state, payload) {
    state.isModalForCreatingSystemadministratorOpen = payload.value;
  },
  SET_STATE_OF_MODAL_FOR_CREATING_USER(state, payload) {
    state.isModalForCreatingUserOpen = payload.value;
  },
  SET_USER(state, payload) {
    const { user } = payload;

    const userExists = state.users.find(
      currentUser => currentUser.id === user.id,
    );

    if (userExists) {
      const users = [...state.users];
      const indexOfMatchingUser = users.findIndex(
        currentUser => currentUser.id === user.id,
      );

      users[indexOfMatchingUser] = user;

      state.users = users;
    } else {
      state.users.push(user);
    }
  },
  SET_USERS(state, payload) {
    state.users = payload.users;
  },
  SET_USERS_STATUS(state, payload) {
    state.status = payload.status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
