<template>
  <div>
    <Stack
      v-for="group in internalSizeGroups"
      :key="group.type"
      class="size-matrix"
    >
      <h3 v-if="group.title" class="size-matrix__title">
        {{ getDictionaryEntry("SizeGroup.B2B." + group.title) }}
      </h3>
      <div class="size-matrix__container">
        <div class="size-matrix__inputs">
          <ol class="size-matrix__sizes">
            <li
              v-for="size in group.sizes"
              :key="size.sku"
              class="size-matrix__size"
            >
              <div class="size-matrix__size-title">
                {{ getPreferredSizeChartValue(size) }}
              </div>
              <div class="size-matrix__quantity-and-stock">
                <input
                  v-model.number="size.quantity"
                  min="0"
                  oninput="this.value = Math.abs(this.value) > 0 ? Math.abs(this.value) : null"
                  placeholder="0"
                  type="number"
                  class="size-matrix__quantity"
                />
                <StockIndicatorForBulk
                  v-if="size && size.inventory"
                  :indicator-only="true"
                  :inventory="size.inventory"
                />
              </div>
            </li>
          </ol>
        </div>
        <div class="size-matrix__total">
          <div class="size-matrix__total-title">
            {{ getDictionaryEntry("Common.Labels.Total") }}
          </div>
          <div class="size-matrix__total-quantity">
            {{ getTotalOfGroup(group) }}
          </div>
        </div>
      </div>
    </Stack>
    <Stack :double="true" class="size-matrix__footer">
      <div class="size-matrix__footer-block">
        <SubstituteProducts />
      </div>
      <div class="size-matrix__footer-block">
        <Stack>
          <PriceSummary
            :currency="totalPrice.currency"
            :discount="totalPrice.amountOfDiscount"
            :quantity="totalNumberOfItems"
            :subtotal="totalPrice.normalPrice"
            :total="totalPrice.price"
          />
        </Stack>
      </div>
    </Stack>
  </div>
</template>

<script>
import cloneDeep from "lodash.clonedeep";
import { mapGetters } from "vuex";
import PriceSummary from "@/components/PriceSummary";
import Stack from "@/components/Stack";
import StockIndicatorForBulk from "@/components/StockIndicatorForBulk";
import SubstituteProducts from "@/components/SubstituteProducts";

export default {
  name: "SizeMatrix",
  components: {
    PriceSummary,
    Stack,
    StockIndicatorForBulk,
    SubstituteProducts,
  },
  props: {
    close: {
      required: true,
      type: Function,
    },
    sizeGroups: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      initialInternalSizeGroups: [],
      internalSizeGroups: [],
    };
  },
  computed: {
    ...mapGetters("basket", [
      "basketLineQuantity",
      "basketLineQuantityForCustomer",
      "basketLines",
      "selectedCustomerForAddingToBasket",
    ]),
    ...mapGetters("products", ["currentVariant"]),
    totalNumberOfItems() {
      let quantity = 0;

      this.internalSizeGroups.forEach(
        sizeGroup => (quantity += this.getTotalOfGroup(sizeGroup)),
      );

      return quantity;
    },
    totalPrice() {
      const {
        convertNumberToLocaleString,
        internalSizeGroups,
        normalizePrice,
      } = this;

      let totalAmountOfDiscount = 0;
      let totalCurrency = "";
      let totalIsDiscounted = false;
      let totalNormalPrice = 0;
      let totalPrice = 0;

      const sizes = internalSizeGroups.map(group => group.sizes).flat();

      sizes.forEach(size => {
        const {
          price: { currency, isDiscounted, normalPrice, price },
          quantity,
        } = size;

        if (!totalCurrency) totalCurrency = currency;
        if (!quantity) return;
        if (!totalIsDiscounted) totalIsDiscounted = isDiscounted;

        totalNormalPrice += (parseInt(normalizePrice(normalPrice)) * quantity);
        totalPrice += (parseFloat(normalizePrice(price)) * quantity);
        totalAmountOfDiscount = totalNormalPrice - totalPrice;
      });

      return {
        amountOfDiscount: totalAmountOfDiscount
          ? convertNumberToLocaleString(totalAmountOfDiscount)
          : null,
        currency: totalCurrency,
        isDiscounted: totalIsDiscounted,
        normalPrice: convertNumberToLocaleString(totalNormalPrice),
        price: convertNumberToLocaleString(totalPrice),
      };
    },
  },
  watch: {
    basketLines: {
      handler: function() {
        this.updateSizes();
      },
      deep: true,
    },
    currentVariant() {
      this.updateSizes();
    },
    initialInternalSizeGroups: {
      handler: function(newValue) {
        this.$emit("initial-size-groups-updated", newValue);
      },
      deep: true,
    },
    internalSizeGroups: {
      handler: function(newValue) {
        this.$emit("size-groups-updated", newValue);
      },
      deep: true,
    },
  },
  mounted() {
    this.updateSizes();
  },
  methods: {
    getQuantityOfSize(sku) {
      if (!sku) return 0;

      if (this.selectedCustomerForAddingToBasket) {
        return this.basketLineQuantityForCustomer({
          customerId: this.selectedCustomerForAddingToBasket,
          sku,
        });
      }

      return this.basketLineQuantity(sku);
    },
    getTotalOfGroup(group) {
      if (!group || !group.sizes || !group.sizes.length) return 0;

      let totalQuantity = 0;

      group.sizes.forEach(size => {
        totalQuantity += size.quantity || 0;
      });

      return totalQuantity;
    },
    updateSizes() {
      const sizeGroups = cloneDeep(this.sizeGroups);

      sizeGroups.forEach(group => {
        group.sizes.forEach(size => {
          size.quantity = this.getQuantityOfSize(size.sku) || "";
        });
      });

      this.initialInternalSizeGroups = sizeGroups;
      this.internalSizeGroups = cloneDeep(sizeGroups);
    },
  },
};
</script>

<style lang="scss" scoped>
.size-matrix {
  &__container {
    display: flex;
    position: relative;

    &::before {
      background-color: var(--color-matrix-title-background);
      content: "";
      height: 2.5rem;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;

    &-block {
      min-width: 0;

      &:first-child {
        flex: 1 1 auto;
        margin-right: var(--spacing-stack);

        @media (min-width: 64rem) {
          margin-right: calc(var(--spacing-stack) * 2);
        }

        @media (min-width: 120rem) {
          margin-right: 17.5rem;
        }
      }

      &:last-child {
        flex: 0 0 auto;
      }
    }
  }

  &__inputs {
    border: 1px solid var(--color-input-border);
    border-width: 0 0 1px 1px;
    flex: 1 1 auto;
    overflow-x: auto;
    overflow-y: hidden;
  }

  &__quantity {
    appearance: none;
    background-color: transparent;
    border-radius: 0;
    border: none;
    font-size: 1rem;
    margin: 0;
    outline: none;
    padding: var(--spacing-input-field);
    position: relative;
    text-align: center;
    width: 100%;
    z-index: 2;

    &:disabled {
      background-color: var(--color-input-border) !important;
      cursor: not-allowed;

      &::placeholder {
        color: rgba(0, 0, 0, 0.5);
      }

      &:-ms-input-placeholder {
        color: rgba(0, 0, 0, 0.5);
      }

      &::-ms-input-placeholder {
        color: rgba(0, 0, 0, 0.5);
      }
    }

    &:focus {
      border-color: var(--color-button-background);
      outline: none;
      z-index: 2;

      &::placeholder {
        color: transparent;
      }

      &:-ms-input-placeholder {
        color: transparent;
      }

      &::-ms-input-placeholder {
        color: transparent;
      }
    }

    &[type="number"] {
      -moz-appearance: textfield;
    }

    &[type="number"]::-webkit-inner-spin-button,
    &[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }
  }

  &__quantity-and-stock {
    border: 1px solid var(--color-input-border);
    border-width: 0 1px 0 0;
    flex: 1 1 auto;

    .size-matrix__size:first-child {
      border-width: 0 1px;
    }
  }

  &__size {
    display: flex;
    flex-direction: column;
    flex-basis: calc(var(--width-counter) * 0.6);
    flex-grow: 0;
    flex-shrink: 0;
    position: relative;
    width: calc(var(--width-counter) * 0.6);

    &-title {
      background-color: var(--color-matrix-title-background);
      color: var(--color-matrix-title-text);
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 2.5rem;
      overflow: hidden;
      text-align: center;
      text-overflow: ellipsis;
      text-transform: uppercase;
      white-space: nowrap;
    }
  }

  &__sizes {
    @include reset-list();

    display: flex;

    &-container {
      border: 1px solid var(--color-input-border);
      border-width: 0 0 1px 1px;
      flex: 1 1 auto;
      overflow-x: auto;
    }
  }

  &__title {
    color: #000000;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 2.5rem;
    white-space: nowrap;
  }

  &__total {
    background-color: var(--color-matrix-total-background);
    color: var(--color-matrix-total-text);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
    z-index: 3;

    &::before {
      background: linear-gradient(
        270deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.25) 80%,
        rgba(0, 0, 0, 0) 100%
      );
      bottom: 0;
      content: "";
      left: 0;
      position: absolute;
      right: -0.5rem;
      top: 0;
      transform: translateX(-0.5rem);
      z-index: -1;
    }

    &-quantity {
      align-items: center;
      display: flex;
      flex: 1 1 auto;
      font-size: 1rem;
      font-weight: 400;
      justify-content: center;
    }

    &-quantity,
    &-title {
      position: relative;
      z-index: 2;
    }

    &-title {
      font-size: 0.875rem;
      font-weight: 500;
      overflow: hidden;
      padding: 0.6rem;
      text-align: center;
      text-overflow: ellipsis;
      text-transform: uppercase;
      white-space: nowrap;
    }
  }
}
</style>
