<template>
  <div class="size-list">
    <Stack :half="true">
      <h3 class="size-list__title">
        {{ getDictionaryEntry("Product.SelectSize") }}
      </h3>
    </Stack>
    <Stack :half="true">
      <ol class="size-list__list">
        <li
          v-for="(size, index) in sizes"
          :key="size.sku"
          class="size-list__item"
        >
          <div class="size-list__radio">
            <input
              :id="`${index}-${id}`"
              v-model="selectedSize"
              :value="size"
              class="size-list__radio-input"
              :name="`size-list-${id}`"
              type="radio"
            />
            <label :for="`${index}-${id}`" class="size-list__radio-label">{{
              getPreferredSizeChartValue(size)
            }}</label>
          </div>
        </li>
      </ol>
    </Stack>
    <Stack v-if="selectedSize" class="size-list__blocks">
      <div class="size-list__block">
        <StockIndicatorForBulk :inventory="selectedSize.inventory" />
      </div>
      <div class="size-list__block">
        <Stack
          v-if="selectedSize.retailPrice && selectedSize.retailPrice.price"
          :quarter="true"
          class="size-list__price size-list__price--rrp"
        >
          {{ getDictionaryEntry("Product.RecommendedRetailPriceAbbreviation") }}
          {{ selectedSize.retailPrice.price }}
          {{ selectedSize.retailPrice.currency }}
        </Stack>
        <Stack
          v-if="selectedSize.price && selectedSize.price.price"
          :half="true"
          class="size-list__price"
        >
          <span
            v-if="
              selectedSize.price &&
                selectedSize.price.isDiscounted &&
                selectedSize.price.normalPrice
            "
            class="size-list__price size-list__price--previous"
          >
            {{ selectedSize.price.normalPrice }}
            {{ selectedSize.price.currency }}
          </span>
          {{ selectedSize.price.price }}
          {{ selectedSize.price.currency }}
        </Stack>
        <Stack>
          <Counter
            v-if="selectedSize.sku"
            :key="selectedSize.sku"
            :sku="selectedSize.sku"
          />
        </Stack>
      </div>
    </Stack>
    <Stack>
      <SubstituteProducts />
    </Stack>
  </div>
</template>

<script>
import { uuid } from "@/helpers/uuid";
import Counter from "@/components/Counter";
import Stack from "@/components/Stack";
import StockIndicatorForBulk from "@/components/StockIndicatorForBulk";
import SubstituteProducts from "@/components/SubstituteProducts";

export default {
  name: "SizeList",
  components: {
    Counter,
    Stack,
    StockIndicatorForBulk,
    SubstituteProducts,
  },
  props: {
    sizes: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      id: uuid(),
      selectedSize: null,
    };
  },
  watch: {
    sizes(newValue) {
      if (newValue && newValue.length) {
        this.selectedSize = newValue[0];
      }
    },
  },
  created() {
    const { sizes } = this;

    if (sizes && sizes.length) {
      this.selectedSize = sizes[0];
    }
  },
};
</script>

<style lang="scss" scoped>
.size-list {
  line-height: normal;

  .product-details & {
    margin-top: var(--spacing-modal);

    @media (min-width: 64rem) {
      margin-top: var(--spacing-modal-large);
    }
  }

  &__block {
    &:first-child {
      display: flex;
      flex: 0 1 auto;
      flex-direction: column;
      justify-content: flex-end;
    }
  }

  &__blocks {
    display: flex;
    justify-content: space-between;
  }

  &__item {
    margin: 0 0.5rem 0.5rem 0;
  }

  &__list {
    @include reset-list();

    display: flex;
    flex-flow: row wrap;
  }

  &__price {
    font-size: 1rem;
    font-weight: 700;
    text-align: right;

    &--previous {
      color: var(--color-error);
      margin-right: 0.5rem;
      padding-top: calc(var(--size-size-icon) * 0.15);
      text-decoration: line-through;
    }

    &--previous,
    &--rrp {
      font-size: 0.95rem;
      font-weight: normal;
    }

    &--rrp {
      opacity: 0.5;
      padding-top: calc(var(--size-size-icon) * 0.15);
    }
  }

  &__radio {
    &-input {
      height: 0;
      left: 0;
      margin: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      width: 0;
    }

    &-label {
      align-items: center;
      border: 1px solid var(--color-radio-border);
      border-radius: 0.25rem;
      cursor: pointer;
      display: flex;
      justify-content: center;
      padding: 0.75rem;
      position: relative;
      transition: border-color 300ms ease;

      .size-list__radio-input:checked + & {
        border-color: var(--color-radio-border-active);
      }

      &::before {
        bottom: 0;
        border-radius: 0.25rem;
        box-shadow: 0 0 0 3px var(--color-radio-box-shadow);
        content: "";
        left: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        transition: opacity 300ms ease;
        top: 0;
        z-index: -1;

        .size-list__radio-input:checked + &,
        .size-list__radio-input:focus + &,
        .size-list__radio-input:hover + & {
          opacity: 1;
        }
      }
    }
  }

  &__title {
    font-size: 1rem;
    margin: 0;
  }
}
</style>
