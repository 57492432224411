<template>
  <div
    v-if="inventory"
    class="stock"
    :class="{ 'stock--indicator-only': indicatorOnly }"
  >
    <div v-if="inventory.showStock" class="stock__amount">
      {{ getDictionaryEntry("Product.InStock") }}: {{ inventory.stock || 0 }}
    </div>
    <div class="stock__indicator" :class="`stock__indicator--${status}`"></div>
    <div
      v-if="shouldShowRestockDate && inventory.restockDate"
      class="stock__date"
      :title="
        `${getDictionaryEntry('Common.Labels.ExpectedAbbreviation')}: ${
          inventory.restockDate
        }`
      "
    >
      {{ inventory.restockDate }}
    </div>
    <div
      v-else-if="!shouldShowRestockDate && inventory.stockMessage"
      class="stock__date"
    >
      {{ getDictionaryEntry(inventory.stockMessage) }}
    </div>
  </div>
</template>

<script>
import { stockLevels } from "@/helpers/stockLevels";

export default {
  name: "StockIndicatorForBulk",
  props: {
    indicatorOnly: {
      default: false,
      type: Boolean,
    },
    inventory: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      status: "",
      text: "",
    };
  },
  computed: {
    shouldShowRestockDate() {
      const { inventory } = this;

      if (!inventory) return false;

      return inventory.restockDate && inventory.level === stockLevels.RED;
    },
  },
  watch: {
    inventory(newValue) {
      if (newValue) {
        this.updateStatusAndText(newValue);
      }
    },
  },
  created() {
    if (!this.inventory) return;

    this.updateStatusAndText(this.inventory);
  },
  methods: {
    updateStatusAndText({ level, stockLevel }) {
      switch (level) {
        case stockLevels.GREY:
        case stockLevels.ORANGE:
        case stockLevels.RED:
          this.status = level;
          break;
        default:
          this.status = stockLevels.GREEN;
          break;
      }

      switch (stockLevel) {
        case stockLevels.GREY:
          this.text = this.getDictionaryEntry("Product.StockDescriptionGrey");
          break;
        case stockLevels.RED:
          this.text = this.getDictionaryEntry("Product.StockDescriptionRed");
          break;
        case stockLevels.ORANGE:
          this.text = this.getDictionaryEntry("Product.StockDescriptionYellow");
          break;
        default:
          this.text = this.getDictionaryEntry("Product.StockDescriptionGreen");
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.stock {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-right: 1rem;

  .size-list & {
    flex-flow: row wrap;
    height: 2.5rem; //mimic height of button
    justify-content: flex-start;
    position: relative;
  }

  .size-list-bulk & {
    display: inline-flex;
    height: calc(var(--width-counter) * (1 / 3));
  }

  .size-matrix & {
    border-top: 1px solid var(--color-input-border);
    flex-flow: row wrap;
  }

  &--indicator-only {
    margin: 0;
  }

  &__amount {
    .size-matrix & {
      font-size: 0.75rem;
      padding: 0 0.25rem;
    }
  }

  &__amount,
  &__date {
    flex: 0 0 100%;
    margin: 0.35rem 0;
    text-align: center;

    .size-list & {
      margin: 0;
      padding-left: calc(var(--size-stock-indicator) * 2);
      text-align: left;
    }
  }

  &__date {
    font-size: 0.75rem;

    .size-matrix & {
      margin-top: 0;
    }
  }

  &__indicator {
    border-radius: 0;
    bottom: 0;
    flex: 0 0 auto;
    height: var(--size-stock-indicator-in-size-matrix);
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 2;

    .size-list & {
      border-radius: 50%;
      bottom: auto;
      height: var(--size-stock-indicator);
      right: auto;
      top: 50%;
      transform: translateY(-50%);
      width: var(--size-stock-indicator);
    }

    &--green {
      background-color: var(--color-stock-indicator-green);
    }

    &--grey {
      background-color: var(--color-stock-indicator-grey);
    }

    &--orange {
      background-color: var(--color-stock-indicator-orange);
    }

    &--red {
      background-color: var(--color-stock-indicator-red);
    }
  }
}
</style>
