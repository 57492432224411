var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input",class:{
    'input--borderless': !_vm.hasBorder,
    'input--error': _vm.hasError,
    'input--large': _vm.large,
    'input--visible-label': !_vm.hideLabel,
  }},[_c('label',_vm._b({staticClass:"input__label",class:{
      'input__label--hidden': _vm.hideLabel,
      'input__label--read-only': _vm.readOnly,
      'input__label--title': _vm.labelAsTitle,
    },attrs:{"for":_vm.id}},'label',_vm.$attrs,false),[_vm._v(_vm._s(_vm.label))]),(_vm.readOnly)?[_c('div',{staticClass:"input__value"},[_vm._v(_vm._s(_vm.internalValue))])]:[_c('div',{staticClass:"input__field-container"},[_c('input',_vm._g(_vm._b({staticClass:"input__field",class:{
          'input__field--center': _vm.centerText,
          'input__field--round': _vm.round,
          'input__field--suffix': _vm.suffix,
        },attrs:{"id":_vm.id,"type":_vm.type},domProps:{"value":_vm.internalValue},on:{"input":function($event){_vm.internalValue = $event.target.value}}},'input',_vm.$attrs,false),_vm.$listeners)),_c('transition',{attrs:{"mode":"out-in","name":"fade"}},[(_vm.type === 'search' && !_vm.loading && _vm.internalValue === '')?_c('svg',{staticClass:"input__search",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('defs'),_c('circle',{attrs:{"cx":"11","cy":"11","r":"8"}}),_c('path',{attrs:{"d":"M21 21l-4.35-4.35"}})]):(_vm.type === 'search' && !_vm.loading && _vm.internalValue !== '')?_c('svg',{staticClass:"input__search input__search--clickable",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"},on:{"click":_vm.clearValue}},[_c('defs'),_c('path',{attrs:{"d":"M18 6L6 18M6 6l12 12"}})]):_vm._e()]),(_vm.suffix)?_c('div',{staticClass:"input__suffix"},[_vm._v(" "+_vm._s(_vm.suffix)+" ")]):_vm._e()],1)],_c('Error',{attrs:{"message":_vm.errorMessage,"visible":_vm.hasError}}),_c('Loader',{staticClass:"input__loader",attrs:{"light":_vm.lightLoader,"visible":_vm.loading}}),(_vm.link)?_c('router-link',{staticClass:"input__link",attrs:{"to":_vm.link}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }