<template>
  <div
    class="group-selector"
    :class="{ 'group-selector--open': isSelectorOpen }"
  >
    <ToggleSwitch
      ref="shopToSwitch"
      :label="
        isShopToState ? '' : getDictionaryEntry('Common.Labels.ShopToEmployee')
      "
      :label-on-right-side="true"
      :value="isShopToState"
      @input="toggleShopTo"
    />
    <button
      v-if="isShopToState"
      class="group-selector__toggle"
      @click="toggleSelector"
    >
      <span v-if="initialSelectedGroups.length"
        >{{ firstSelectedGroup.name }} ({{ firstSelectedGroup.currency }})
        <span
          v-if="initialSelectedGroups.length > 1"
          class="group-selector__toggle-surplus"
          >+{{ initialSelectedGroups.length - 1 }}</span
        ></span
      >
      <span v-else>{{
        getDictionaryEntry("Common.Placeholders.SelectOneOrMultipleGroups")
      }}</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="group-selector__icon"
        viewBox="0 0 24 24"
      >
        <path d="m6 9 6 6 6-6" />
      </svg>
    </button>
    <div ref="selector" class="group-selector__box-container">
      <div class="group-selector__box">
        <ol class="group-selector__list">
          <li
            v-for="group in groupsWithHierarchy"
            :key="group.userGroupId"
            class="group-selector__item"
          >
            <Checkbox
              :disabled="!isCurrencyAllowed(group.currency)"
              :label="`${group.name} (${group.currency})`"
              :value="isGroupSelected(group.userGroupId)"
              @input="
                toggleGroup({
                  currency: group.currency,
                  id: group.userGroupId,
                  value: $event,
                })
              "
            />
            <ol
              v-if="group.children && group.children.length"
              class="group-selector__list"
            >
              <li
                v-for="child in group.children"
                :key="child.userGroupId"
                class="group-selector__item group-selector__item--child"
              >
                <Checkbox
                  :disabled="!isCurrencyAllowed(child.currency)"
                  :label="`${child.name} (${child.currency})`"
                  :value="isGroupSelected(child.userGroupId)"
                  @input="
                    toggleGroup({
                      currency: child.currency,
                      id: child.userGroupId,
                      value: $event,
                    })
                  "
                />
              </li>
            </ol>
          </li>
        </ol>
        <transition name="fade">
          <div
            v-if="selectedGroups.length || hasSelectedGroupsChanged"
            :class="{
              'group-selector__action--reset': !hasSelectedGroupsChanged,
            }"
            class="group-selector__action"
          >
            <transition name="fade" mode="out-in">
              <Button
                v-if="hasSelectedGroupsChanged"
                :show-loader="isSaving"
                @click="selectGroups(false)"
                >{{ getDictionaryEntry("ShopTo.SaveChanges") }}</Button
              >
              <Button v-else :link="true" @click="resetGroups">{{
                getDictionaryEntry("ShopTo.ResetGroups")
              }}</Button>
            </transition>
          </div>
        </transition>
      </div>
    </div>
    <transition name="fade">
      <div
        v-if="isSelectorOpen"
        class="group-selector__underlay"
        @click="closeSelector"
      ></div>
    </transition>
    <Modal
      v-if="isShopToModalOpen"
      :title="
        hasSelectedCurrencyChanged
          ? getDictionaryEntry('ShopTo.EmptyBasketModal.TitleForCurrencyChange')
          : getDictionaryEntry('ShopTo.EmptyBasketModal.TitleForStateChange')
      "
      :visible="isShopToModalOpen"
      class="checkout__error-modal"
      @close="closeShopToModal"
    >
      <template #body>
        <div class="block__rich-text">
          <p>
            {{
              hasSelectedCurrencyChanged
                ? getDictionaryEntry(
                    "ShopTo.EmptyBasketModal.DescriptionForCurrencyChange",
                  )
                : getDictionaryEntry(
                    "ShopTo.EmptyBasketModal.DescriptionForStateChange",
                  )
            }}
          </p>
        </div>
      </template>
      <template #footer>
        <Button :limited-width="true" :secondary="true" @click="cancel">
          {{ getDictionaryEntry("Common.Cancel") }}
        </Button>
        <Button
          :limited-width="true"
          :show-loader="isSaving"
          @click="
            hasSelectedCurrencyChanged
              ? selectGroups(true)
              : emptyBasketAndEnableShopTo()
          "
          >{{
            getDictionaryEntry("ShopTo.EmptyBasketModal.ContinueButton")
          }}</Button
        >
      </template>
    </Modal>
  </div>
</template>

<script>
import cloneDeep from "lodash.clonedeep";
import xor from "lodash.xor";
import { mapActions, mapGetters } from "vuex";
import Button from "@/components/Button";
import Checkbox from "@/components/Checkbox";
import Modal from "@/components/Modal";
import ToggleSwitch from "@/components/ToggleSwitch";
import { slideDown, slideUp } from "@/helpers/slide";
import { statuses } from "@/helpers/statuses";

export default {
  name: "ShopToGroupSelector",
  components: {
    Button,
    Checkbox,
    Modal,
    ToggleSwitch,
  },
  data() {
    return {
      initialSelectedGroups: [],
      isSelectorOpen: false,
      isShopToModalOpen: false,
      selectedGroups: [],
    };
  },
  computed: {
    ...mapGetters("authentication", ["isShopToState"]),
    ...mapGetters("basket", ["numberOfItemsInBasket"]),
    ...mapGetters("shopToUser", ["groupsToShopTo", "statusOfShopTo"]),
    firstSelectedGroup() {
      if (!this.initialSelectedGroups.length) return "";

      return this.groupsToShopTo.find(
        group => group.userGroupId === this.initialSelectedGroups[0],
      );
    },
    groupsWithHierarchy() {
      const { groupsToShopTo } = this;

      if (!groupsToShopTo || !groupsToShopTo.length) return [];

      const childGroups = groupsToShopTo.filter(
        group => group.userGroupParentId,
      );
      const parentGroups = groupsToShopTo.filter(
        group => !group.userGroupParentId,
      );

      return parentGroups
        .map(parentGroup => ({
          ...parentGroup,
          children:
            childGroups && childGroups.length
              ? childGroups
                  .filter(
                    childGroup =>
                      childGroup.userGroupParentId === parentGroup.userGroupId,
                  )
                  .sort((a, b) => a.name.localeCompare(b.name))
              : [],
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    hasSelectedCurrencyChanged() {
      const {
        groupsToShopTo,
        hasSelectedGroupsChanged,
        initialSelectedGroups,
        selectedGroups,
      } = this;

      if (!hasSelectedGroupsChanged) return false;

      const firstInitialGroup = groupsToShopTo.find(
        group => group.userGroupId === initialSelectedGroups[0],
      );
      const firstSelectedGroup = groupsToShopTo.find(
        group => group.userGroupId === selectedGroups[0],
      );

      if (!(firstInitialGroup && firstSelectedGroup)) return true;

      return firstInitialGroup.currency !== firstSelectedGroup.currency;
    },
    hasSelectedGroupsChanged() {
      return xor(this.initialSelectedGroups, this.selectedGroups).length !== 0;
    },
    isSaving() {
      return this.statusOfShopTo === statuses.SAVING;
    },
  },
  watch: {
    groupsToShopTo: {
      deep: true,
      handler(newValue) {
        const selectedGroupIds = newValue
          .filter(group => group.isSelected)
          .map(group => group.userGroupId);

        this.initialSelectedGroups = cloneDeep(selectedGroupIds);
        this.selectedGroups = cloneDeep(selectedGroupIds);
      },
    },
  },
  created() {
    this.GET_GROUPS_TO_SHOP_TO();
  },
  methods: {
    ...mapActions("basket", ["GET_BASKET"]),
    ...mapActions("shopToUser", [
      "ACTIVATE_SHOP_TO_MODE",
      "DEACTIVATE_SHOP_TO_MODE",
      "GET_GROUPS_TO_SHOP_TO",
      "UPDATE_GROUPS_TO_SHOP_TO",
    ]),
    cancel() {
      const selectedGroupIds = this.groupsToShopTo
        .filter(group => group.isSelected)
        .map(group => group.userGroupId);

      this.selectedGroups = cloneDeep(selectedGroupIds);

      this.closeSelector();
      this.closeShopToModal();
    },
    closeSelector() {
      if (!this.isSelectorOpen) return;

      slideUp(this.$refs.selector);

      this.isSelectorOpen = false;
    },
    closeShopToModal() {
      this.isShopToModalOpen = false;
    },
    emptyBasketAndEnableShopTo() {
      const {
        ACTIVATE_SHOP_TO_MODE,
        DEACTIVATE_SHOP_TO_MODE,
        GET_GROUPS_TO_SHOP_TO,
        closeShopToModal,
        openSelector,
        isShopToState,
      } = this;

      if (isShopToState) {
        DEACTIVATE_SHOP_TO_MODE().then(() => {
          closeShopToModal();
        });
      } else {
        GET_GROUPS_TO_SHOP_TO();
        ACTIVATE_SHOP_TO_MODE().then(() => {
          closeShopToModal();
          openSelector();
        });
      }
    },
    isCurrencyAllowed(currency) {
      if (!currency) return false;

      if (!this.selectedGroups.length) return true;

      const selectedGroupToShopTo = this.groupsToShopTo.find(
        group => group.userGroupId === this.selectedGroups[0],
      );

      if (!selectedGroupToShopTo) return true;

      return selectedGroupToShopTo.currency === currency;
    },
    isGroupSelected(groupId) {
      if (!groupId) return false;

      return this.selectedGroups.includes(groupId);
    },
    openSelector() {
      if (this.isSelectorOpen) return;

      slideDown(this.$refs.selector);

      this.isSelectorOpen = true;
    },
    openShopToModal() {
      this.isShopToModalOpen = true;
    },
    resetGroups() {
      this.selectedGroups = [];
      this.selectGroups();
    },
    selectGroups(confirmed) {
      if (
        this.hasSelectedCurrencyChanged &&
        this.numberOfItemsInBasket &&
        !confirmed
      ) {
        this.openShopToModal();
        return;
      }

      this.UPDATE_GROUPS_TO_SHOP_TO(this.selectedGroups).then(() => {
        this.GET_BASKET();
        this.closeSelector();
        this.closeShopToModal();
      });
    },
    toggleGroup({ currency, id, value }) {
      if (!currency || !id) return;

      if (value) {
        if (this.selectedGroups.includes(id)) return;

        this.selectedGroups.push(id);
      } else {
        this.selectedGroups = this.selectedGroups.filter(
          selectedGroup => selectedGroup !== id,
        );
      }
    },
    toggleSelector() {
      if (this.isSelectorOpen) {
        this.closeSelector();
      } else {
        this.openSelector();
      }
    },
    toggleShopTo() {
      const {
        $refs,
        ACTIVATE_SHOP_TO_MODE,
        DEACTIVATE_SHOP_TO_MODE,
        GET_GROUPS_TO_SHOP_TO,
        isShopToState,
        numberOfItemsInBasket,
        openSelector,
        openShopToModal,
      } = this;

      if (numberOfItemsInBasket === 0) {
        if (isShopToState) {
          DEACTIVATE_SHOP_TO_MODE();
        } else {
          GET_GROUPS_TO_SHOP_TO();
          ACTIVATE_SHOP_TO_MODE().then(() => {
            openSelector();
          });
        }
      } else {
        //Manually force the checkbox UI to match the ToggleSwitch state
        $refs.shopToSwitch.$el.querySelector(
          "input[type='checkbox']",
        ).checked = isShopToState;

        openShopToModal();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.group-selector {
  align-items: center;
  display: flex;
  height: 3.3125rem;
  position: relative;

  @media (min-width: 64rem) {
    height: 2.5rem;
  }

  &__action {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
    position: relative;

    &--reset {
      margin-top: 1.5rem;
      padding-top: 1.25rem;

      &::before {
        opacity: 1 !important;
      }
    }

    &::before {
      border-top: 1px solid #dfdedd;
      content: "";
      left: 0;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: opacity 300ms ease;
    }
  }

  &__box {
    padding: 1.5rem 1rem;

    &-container {
      background-color: #faf9f8;
      box-shadow: var(--elevation-8);
      display: none;
      left: calc(var(--spacing-container) * -1);
      overflow: hidden;
      position: absolute;
      right: calc(var(--spacing-container) * -1);
      top: 100%;
      z-index: 3;

      @media (min-width: 64rem) {
        left: 0;
        right: 0;
      }
    }
  }

  &__icon {
    fill: none;
    height: var(--size-dropdown-icon);
    margin-left: 1rem;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
    stroke: var(--color-text-primary);
    transition: transform 300ms ease;
    width: var(--size-dropdown-icon);

    .group-selector--open & {
      transform: rotate(180deg);
    }
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 0.875rem;
    }

    &--child {
      margin-left: 0.875rem;

      &:first-child {
        margin-top: 0.875rem;
      }
    }
  }

  &__list {
    @include reset-list();
  }

  &__toggle {
    @include reset-button();

    align-items: center;
    display: flex;
    flex: 1 1 auto;
    font-size: 0.875rem;
    font-weight: 700;
    justify-content: space-between;
    height: 100%;

    &-surplus {
      color: #9e9e9e;
      margin-left: 0.375rem;
    }
  }

  &__underlay {
    background-color: rgba(0, 0, 0, 0.75);
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: -1;

    @media (min-width: 64rem) {
      background-color: transparent;
      z-index: 2;
    }
  }
}
</style>
