<template>
  <ul v-if="product" class="swatches">
    <li
      v-for="variant in variants"
      :key="'swatches_' + variant.id"
      class="swatch"
    >
      <router-link
        class="swatch__trigger"
        :title="variant.alt"
        :to="detailsLink(product.id, variant.id)"
      >
        <img
          :src="getImage(variant)"
          class="swatch__image"
          :alt="variant.swatch.alt"
          :title="variant.swatch.alt"
          heigth="50"
          decoding="async"
          loading="lazy"
          width="50"
        />
      </router-link>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Swatches",
  props: {
    product: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapGetters("branding", ["productFallbackImage"]),
    ...mapGetters("products", ["currentVariant"]),
    variants() {
      if (!this.product) return [];

      return this.product.variants;
    },
  },
  methods: {
    detailsLink(productId, variantId) {
      return {
        query: {
          ...this.$route.query,
          mode: "details",
          productId,
          variantId,
        },
      };
    },
    getImage(variant) {
      if (!variant) return "";

      const { images, swatch } = variant;

      if (swatch && swatch.src) return swatch.src;

      if (!images || !images.length || !images[0].thumbnail) {
        return this.productFallbackImage;
      }

      return images[0].thumbnail.src || this.productFallbackImage;
    },
  },
};
</script>

<style lang="scss" scoped>
$variant-size: 4rem;

.swatch {
  &:not(:last-child) {
    margin: 0 0.625rem 0.625rem 0;
  }

  &__image {
    display: block;
    height: 100%;
    left: 50%;
    max-height: $variant-size * 0.75 !important;
    max-width: $variant-size * 0.75 !important;
    object-fit: contain;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  &__trigger {
    align-items: center;
    display: flex;
    height: $variant-size;
    justify-content: center;
    position: relative;
    width: $variant-size;

    &::before {
      border: 1px solid rgba(0, 0, 0, 0.1);
      bottom: 0;
      content: "";
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: border-color 300ms ease;
    }

    &.router-link-exact-active {
      &::before {
        border-color: rgba(0, 0, 0, 0.75);
      }
    }
  }
}

.swatches {
  @include reset-list();

  display: flex;
  flex-flow: row wrap;
}
</style>
