<template>
  <div v-if="childrenToShopFor && childrenToShopFor.length" class="selector">
    <div class="selector__toggle">
      <div class="selector__toggle-label">
        {{ getDictionaryEntry("Common.Labels.CompanyBeingShoppedTo") }}:
      </div>
      <button class="selector__toggle-action" @click="toggleSelector">
        <span>{{
          selectedCustomer ? selectedCustomer.customerName : customerName
        }}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="selector__toggle-icon"
          :class="{ 'selector__toggle-icon--rotated': isSelectorOpen }"
          viewBox="0 0 24 24"
        >
          <path d="m6 9 6 6 6-6" />
        </svg>
      </button>
    </div>
    <div ref="selector" class="selector__box">
      <div class="selector__box-content">
        <Stack>
          <h2 class="selector__title">
            {{ getDictionaryEntry("Common.Placeholders.SelectCompany") }}
          </h2>
        </Stack>
        <Stack class="selector__list-container">
          <ul class="selector__list">
            <li class="selector__item">
              <button
                class="selector__item-action"
                @click="selectCustomer(customerId)"
              >
                {{ customerName }}
              </button>
            </li>
            <li
              v-for="child in childrenToShopFor"
              :key="child.customerId"
              class="selector__item"
            >
              <button
                class="selector__item-action"
                @click="selectCustomer(child.customerId)"
              >
                {{ child.customerName }}
                <div
                  v-if="child.discountPercent"
                  class="selector__item-discount"
                >
                  {{ child.discountPercent }}%
                </div>
              </button>
            </li>
          </ul>
        </Stack>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Stack from "@/components/Stack";
import { slideDown, slideUp } from "@/helpers/slide";

export default {
  name: "QuickBuy",
  components: {
    Stack,
  },
  data() {
    return {
      isSelectorOpen: false,
    };
  },
  computed: {
    ...mapGetters("authentication", ["customerId", "customerName"]),
    ...mapGetters("basket", ["selectedCustomerForAddingToBasket"]),
    ...mapGetters("customers", ["childrenToShopFor"]),
    selectedCustomer() {
      const { childrenToShopFor, selectedCustomerForAddingToBasket } = this;

      if (
        !selectedCustomerForAddingToBasket ||
        !childrenToShopFor ||
        !childrenToShopFor.length
      ) {
        return null;
      }

      const matchingCustomer = childrenToShopFor.find(
        customer => customer.customerId === selectedCustomerForAddingToBasket,
      );

      return matchingCustomer || null;
    },
  },
  watch: {
    "$route.query.customerId"(newValue, oldValue) {
      if (
        newValue &&
        newValue !== oldValue &&
        newValue !== this.selectedCustomerForAddingToBasket
      ) {
        this.selectCustomer(newValue);
      }
    },
  },
  created() {
    const {
      $route: { query },
      GET_CHILDREN_TO_SHOP_FOR,
      GET_PRODUCT,
      GET_PRODUCT_FOR_CUSTOMER,
      selectCustomer,
      selectedCustomerForAddingToBasket,
    } = this;

    if (selectedCustomerForAddingToBasket) {
      GET_PRODUCT_FOR_CUSTOMER({
        customerId: selectedCustomerForAddingToBasket,
        productId: query.productId,
      });
    } else {
      GET_PRODUCT({ id: query.productId });
    }

    GET_CHILDREN_TO_SHOP_FOR().then(() => {
      if (
        query.customerId &&
        query.customerId !== selectedCustomerForAddingToBasket
      ) {
        selectCustomer(query.customerId);
      }
    });
  },
  destroyed() {
    this.RESET_CHILDREN_OF_CUSTOMER();
  },
  methods: {
    ...mapActions("basket", ["UPDATE_CUSTOMER_FOR_ADDING_TO_BASKET"]),
    ...mapActions("customers", [
      "GET_CHILDREN_TO_SHOP_FOR",
      "RESET_CHILDREN_OF_CUSTOMER",
    ]),
    ...mapActions("products", ["GET_PRODUCT", "GET_PRODUCT_FOR_CUSTOMER"]),
    closeSelector() {
      this.isSelectorOpen = false;
      slideUp(this.$refs.selector);
    },
    getProduct() {},
    openSelector() {
      this.isSelectorOpen = true;
      slideDown(this.$refs.selector);
    },
    selectCustomer(id) {
      if (!id) return;

      this.$router.push({
        query: {
          ...this.$route.query,
          customerId: id,
        },
      });

      this.UPDATE_CUSTOMER_FOR_ADDING_TO_BASKET({ customerId: id });
      this.GET_PRODUCT_FOR_CUSTOMER({
        customerId: id,
        productId: this.$route.query.productId,
      });
      this.closeSelector();
    },
    toggleSelector() {
      if (this.isSelectorOpen) {
        this.closeSelector();
      } else {
        this.openSelector();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.selector {
  margin-bottom: calc(var(--spacing-modal-large) * -1);
  margin-left: calc(var(--spacing-modal-large) * -1);
  margin-top: var(--spacing-modal-large);
  width: calc(100% + var(--spacing-modal) * 2);

  @media (min-width: 48rem) {
    margin-bottom: calc(var(--spacing-modal-large) * -1);
    margin-left: calc(var(--spacing-modal-large) * -1);
    margin-top: var(--spacing-modal-large);
    width: calc(100% + var(--spacing-modal-large) * 2);
  }

  &__box {
    background-color: #faf9f8;
    bottom: 0;
    display: none;
    left: 0;
    overflow-y: auto;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 10rem;
    z-index: 99;

    &-content {
      height: 100%;
      overflow-y: auto;
      padding: calc(var(--spacing-modal) * 2);

      @media (min-width: 48rem) {
        padding: calc(var(--spacing-modal-large) * 2);
      }
    }
  }

  &__item {
    &::after {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      content: "";
      left: 0;
      position: absolute;
      right: 0;
    }

    &-action {
      @include reset-button();

      align-content: center;
      display: flex;
      font-size: 0.9375rem;
      justify-content: space-between;
      padding: 0.75rem 0;
      text-align: left;
      width: 100%;
    }

    &-discount {
      color: #939393;
      font-size: 0.8125rem;
      margin-left: 3rem;
    }
  }

  &__list {
    @include reset-list();

    display: table;

    &-container {
      position: relative;
    }
  }

  &__title {
    font-size: 1.25em;
    margin: 0;

    @media (min-width: 64rem) {
      font-size: 1.5em;
    }
  }

  &__toggle {
    align-items: center;
    background-color: #faf9f8;
    display: flex;
    padding: var(--spacing-modal);

    @media (min-width: 48rem) {
      padding: var(--spacing-modal-large);
    }

    &-action {
      @include reset-button();

      align-items: center;
      display: flex;
      padding: 0.5rem 1rem;
    }

    &-icon {
      fill: none;
      height: var(--size-customer-selector-icon);
      margin-left: 0.25rem;
      opacity: 0.75;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 2;
      stroke: var(--color-text-primary);
      transition: transform 300ms ease;
      width: var(--size-customer-selector-icon);

      &--rotated {
        transform: rotate(180deg);
      }
    }

    &-label {
      font-weight: 700;
    }
  }
}
</style>
