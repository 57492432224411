<template>
  <div class="image-container">
    <img
      v-if="image.src || image.alt"
      :src="image.src"
      :alt="image.alt"
      class="image"
      decoding="async"
      loading="lazy"
    />
  </div>
</template>

<script>
export default {
  name: "BasketLineProductImage",
  props: {
    image: {
      required: true,
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.image {
  display: block;
  height: 100%;
  left: 50%;
  object-fit: contain;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;

  &-container {
    background-color: var(--color-products-image-background);
    position: relative;
    width: 100%;

    .sidebar & {
      margin-left: 0;
    }

    &::before {
      content: "";
      display: block;
      padding-top: (1 / 1) * 100%;
      width: 100%;
    }
  }
}
</style>
