import axios from "axios";
import isEqual from "lodash.isequal";
import router from "@/router";
import { endpoints } from "@/helpers/endpoints";
import { errorAlert } from "@/helpers/errorAlert";
import { keysForInitialBooleanFilterParams } from "@/helpers/keysForInitialBooleanFilterParams";
import { keysForInitialFacetFilterParams } from "@/helpers/keysForInitialFacetFilterParams";
import { statuses } from "@/helpers/statuses";

const state = () => ({
  currentProduct: null,
  currentProductId: null,
  currentVariantId: null,
  filters: {},
  lastSearchedBooleanFilterParams: [],
  lastSearchedFacetFilterParams: [],
  lastSearchedQuery: "",
  lastSearchedRangeFilterParams: [],
  pagination: {},
  productPricesForConfiguration: [],
  products: [],
  productsForPriceConfiguration: [],
  productsForProductSheetConfiguration: [],
  productsForPurchaseLimitationConfiguration: [],
  productsForWardrobeConfiguration: [],
  retailPriceDiscount: null,
  selectedProductsForPriceConfiguration: [],
  status: "",
  substitutionVariantsForCurrentVariant: [],
  variantsForProductBlocks: [],
  variantsForProductSheet: [],
});

const getters = {
  categoryFilter: state => {
    const { filters } = state;
    if (!filters || !filters.facetFilters) return {};

    const categoryFilter = filters.facetFilters.find(
      filter => filter.id === "category",
    );

    return categoryFilter ? categoryFilter : {};
  },
  currency: state => state.currency,
  currentColorCode: (state, getters) => {
    const { currentVariant } = getters;

    if (!currentVariant || !currentVariant.colorNumber) {
      return null;
    }

    return currentVariant.colorNumber;
  },
  currentPage: state => {
    if (!state.pagination) return 0;

    return state.pagination.currentPage || 0;
  },
  currentProduct: state => state.currentProduct,
  currentProductId: state => state.currentProductId,
  currentVariant: state => {
    const { currentProduct, currentVariantId } = state;
    if (!currentProduct || !currentProduct.variants || !currentVariantId)
      return null;

    return currentProduct.variants.find(
      variant => variant.id === currentVariantId,
    );
  },
  currentVariantId: state => state.currentVariantId,
  filters: state => state.filters,
  lastSearchedQuery: state => state.lastSearchedQuery,
  nameOfCurrentProduct: state => {
    const { currentProduct } = state;
    if (!currentProduct) return null;

    return currentProduct.productName ? currentProduct.productName : null;
  },
  numberOfAppliedFilters: state => {
    const {
      lastSearchedBooleanFilterParams,
      lastSearchedFacetFilterParams,
    } = state;

    const hasAppliedBooleanFilters = !!(
      lastSearchedBooleanFilterParams && lastSearchedBooleanFilterParams.length
    );

    const hasAppliedFacetFilters = !!(
      lastSearchedFacetFilterParams && lastSearchedFacetFilterParams.length
    );

    let numberOfAppliedFilters = 0;

    if (hasAppliedBooleanFilters) {
      numberOfAppliedFilters += lastSearchedBooleanFilterParams.length;
    }

    if (hasAppliedFacetFilters) {
      numberOfAppliedFilters += lastSearchedFacetFilterParams
        .map(filter => filter.values)
        .reduce((sum, current) => sum + current.length, 0);
    }

    return numberOfAppliedFilters;
  },
  pagination: state => state.pagination,
  productPricesForConfiguration: state => state.productPricesForConfiguration,
  products: state => state.products,
  productByVariantId: state => id =>
    state.products.find(product =>
      product.variants.some(variant => variant.id === id),
    ),
  productsForPriceConfiguration: state => state.productsForPriceConfiguration,
  productsForProductSheetConfiguration: state =>
    state.productsForProductSheetConfiguration,
  productsForPurchaseLimitationConfiguration: state =>
    state.productsForPurchaseLimitationConfiguration,
  productsForWardrobeConfiguration: state =>
    state.productsForWardrobeConfiguration,
  retailPriceDiscount: state => state.retailPriceDiscount,
  selectedProductsForPriceConfiguration: state =>
    state.selectedProductsForPriceConfiguration,
  statusOfProducts: state => state.status,
  substitutionVariantsForCurrentProduct: state => {
    const { currentProduct } = state;
    if (!currentProduct || !currentProduct.substitutionVariants) return [];

    return currentProduct.substitutionVariants;
  },
  substitutionVariantsForCurrentVariant: state =>
    state.substitutionVariantsForCurrentVariant,
  totalNumberOfProducts: state => {
    if (!state.pagination) return 0;

    return state.pagination.totalResults;
  },
  variantsForProductBlockByIds: state => ids =>
    state.variantsForProductBlocks.filter(variant =>
      ids.includes(variant.variantId),
    ),
  variantsForProductBlocks: state => state.variantsForProductBlocks,
  variantsForProductSheet: state => state.variantsForProductSheet,
  variantsForProductSheetByIds: state => ids =>
    state.variantsForProductSheet.filter(variant =>
      ids.includes(variant.variantId),
    ),
};

const actions = {
  DELETE_SELECTED_PRODUCTS_FOR_CONFIGURATION({ commit, dispatch }, payload) {
    console.log(commit, dispatch, payload);
  },
  GET_PRODUCT({ commit }, payload) {
    commit("SET_PRODUCTS_STATUS", { status: statuses.LOADING });

    return axios
      .get(`${endpoints.ECOMMERCE}/product/${encodeURIComponent(payload.id)}`)
      .then(({ data }) => {
        commit("SET_PRODUCTS_STATUS", { status: statuses.SUCCESS });
        commit("SET_CURRENT_PRODUCT", { product: data });
      })
      .catch(error => {
        if (error.response.status === 404) {
          router.replace({ name: "noAccess" });
          return;
        }

        commit("SET_PRODUCTS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_PRODUCT_FOR_CUSTOMER({ commit }, payload) {
    commit("SET_PRODUCTS_STATUS", { status: statuses.LOADING });

    return axios
      .get(
        `${endpoints.ECOMMERCE}/product/${encodeURIComponent(
          payload.productId,
        )}/shopForCustomer/${encodeURIComponent(payload.customerId)}`,
      )
      .then(({ data }) => {
        commit("SET_PRODUCTS_STATUS", { status: statuses.SUCCESS });
        commit("SET_CURRENT_PRODUCT", { product: data });
      })
      .catch(error => {
        if (error.response.status === 404) {
          router.replace({ name: "noAccess" });
          return;
        }

        commit("SET_PRODUCTS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_PRODUCTS({ commit, state, rootGetters }, payload) {
    commit("SET_PRODUCTS_STATUS", { status: statuses.LOADING });

    const { isInitialLoad, page, pageSize } = payload;

    let booleanFilterParams =
      payload.booleanFilterParams !== undefined
        ? payload.booleanFilterParams || []
        : state.lastSearchedBooleanFilterParams;

    let facetFilterParams =
      payload.facetFilterParams !== undefined
        ? payload.facetFilterParams || []
        : state.lastSearchedFacetFilterParams;

    const initialBooleanFilterParams =
      rootGetters["content/initialBooleanFilterParams"];

    const initialFacetFilterParams =
      rootGetters["content/initialFacetFilterParams"];

    const rangeFilterParams =
      payload.rangeFilterParams !== undefined
        ? payload.rangeFilterParams || []
        : state.lastSearchedRangeFilterParams;

    let searchQuery =
      payload.searchQuery !== undefined
        ? payload.searchQuery
        : state.lastSearchedQuery;

    const {
      history: {
        current: { query },
      },
    } = router;

    let queryFriendlyParams = {};

    if (isInitialLoad && !!Object.keys(query).length) {
      queryFriendlyParams = query;

      booleanFilterParams = Object.keys(queryFriendlyParams)
        .filter(key => keysForInitialBooleanFilterParams.includes(key))
        .filter(key => !!queryFriendlyParams[key])
        .map(key => ({ key }));

      facetFilterParams = Object.keys(queryFriendlyParams)
        .filter(key => keysForInitialFacetFilterParams.includes(key))
        .map(key => ({ key, values: queryFriendlyParams[key].split("+") }));

      searchQuery = query.search;
    } else {
      booleanFilterParams.forEach(
        filter => (queryFriendlyParams[filter.key] = true),
      );

      facetFilterParams.forEach(filter => {
        queryFriendlyParams[filter.key] = filter.values.join("+");
      });

      if (searchQuery) {
        queryFriendlyParams["search"] = searchQuery;
      }
    }

    if (Object.keys(queryFriendlyParams)) {
      router.push({ query: queryFriendlyParams });
    }

    return axios
      .post(`${endpoints.ECOMMERCE}/product/SearchVariantsForCustomer`, {
        booleanFilterParams,
        facetFilterParams,
        initialBooleanFilterParams,
        initialFacetFilterParams,
        pageNumberZeroBased: page,
        pageSize: pageSize || 18,
        rangeFilterParams,
        searchPhrase: searchQuery,
      })
      .then(({ data }) => {
        commit("SET_PRODUCTS_STATUS", { status: statuses.SUCCESS });
        commit("SET_FILTERS", { filters: data.filters });

        const hasPageChanged = !isEqual(state.pagination.currentPage, page);

        commit("SET_PAGINATION", { pagination: data.pagination });

        const hasBooleanFilterParamsChanged = !isEqual(
          state.lastSearchedBooleanFilterParams,
          booleanFilterParams,
        );
        const hasFacetFilterParamsChanged = !isEqual(
          state.lastSearchedFacetFilterParams,
          facetFilterParams,
        );
        const hasRangeFilterParamsChanged = !isEqual(
          state.lastSearchedRangeFilterParams,
          rangeFilterParams,
        );
        const hasSearchQueryChanged = !isEqual(
          state.lastSearchedQuery,
          searchQuery,
        );

        const shouldAppend =
          hasPageChanged &&
          !hasBooleanFilterParamsChanged &&
          !hasFacetFilterParamsChanged &&
          !hasRangeFilterParamsChanged &&
          !hasSearchQueryChanged;

        commit("SET_PRODUCTS", {
          append: shouldAppend,
          products: data.products,
        });
        commit("SET_LAST_SEARCHED_QUERY", { query: searchQuery });
        commit("SET_LAST_SEARCHED_BOOLEAN_FILTER_PARAMS", {
          booleanFilterParams,
        });
        commit("SET_LAST_SEARCHED_FACET_FILTER_PARAMS", { facetFilterParams });
        commit("SET_LAST_SEARCHED_RANGE_FILTER_PARAMS", { rangeFilterParams });
      })
      .catch(error => {
        commit("SET_PRODUCTS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_PRODUCT_AND_PRICES_FOR_PRICE_CONFIGURATION({ commit }, payload) {
    commit("SET_PRODUCTS_STATUS", { status: statuses.LOADING });

    const { customerId, productId } = payload;

    const getProduct = axios.post(
      `${endpoints.ECOMMERCE}/product/GetProductForEmployeePriceConfiguration`,
      {
        customerId,
        productId,
      },
    );
    const getPrices = axios.post(
      `${endpoints.ECOMMERCE}/price/GetEmployeePrices`,
      {
        customerId,
        productIds: [productId],
      },
    );

    return new Promise((resolve, reject) => {
      axios
        .all([getProduct, getPrices])
        .then(
          axios.spread((...responses) => {
            commit("SET_PRODUCTS_STATUS", { status: statuses.SUCCESS });

            const product = responses[0].data;
            const prices = responses[1].data;

            commit("SET_CURRENT_PRODUCT", { product });
            commit("SET_PRICES_FOR_CONFIGURATION", { prices });

            resolve();
          }),
        )
        .catch(error => {
          commit("SET_PRODUCTS_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject();
        });
    });
  },
  GET_PRODUCTS_FOR_PRICE_CONFIGURATION({ commit }, payload) {
    commit("SET_PRODUCTS_STATUS", { status: statuses.LOADING });

    const { customerId, pageNumberZeroBased, pageSize, searchPhrase } = payload;

    return axios
      .post(
        `${endpoints.ECOMMERCE}/product/SearchProductsForEmployeePriceConfiguration`,
        {
          customerId,
          pageNumberZeroBased,
          pageSize: pageSize || 20,
          searchPhrase,
        },
      )
      .then(({ data }) => {
        commit("SET_PRODUCTS_STATUS", { status: statuses.SUCCESS });
        commit("SET_PRODUCTS_FOR_PRICE_CONFIGURATION", {
          products: data.products,
        });
        commit("SET_PAGINATION", { pagination: data.pagination });
      })
      .catch(error => {
        commit("SET_PRODUCTS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_PRODUCTS_FOR_PRODUCT_SHEET_CONFIGURATION(
    { commit, state, rootGetters },
    payload,
  ) {
    commit("SET_PRODUCTS_STATUS", { status: statuses.LOADING });

    const { isInitialLoad, page, pageSize } = payload;

    let booleanFilterParams =
      payload.booleanFilterParams !== undefined
        ? payload.booleanFilterParams || []
        : state.lastSearchedBooleanFilterParams;

    const divisionId = rootGetters["branding/divisionId"];

    let facetFilterParams =
      payload.facetFilterParams !== undefined
        ? payload.facetFilterParams || []
        : state.lastSearchedFacetFilterParams;

    const initialBooleanFilterParams =
      rootGetters["content/initialBooleanFilterParams"];

    const initialFacetFilterParams =
      rootGetters["content/initialFacetFilterParams"];

    const rangeFilterParams =
      payload.rangeFilterParams !== undefined
        ? payload.rangeFilterParams || []
        : state.lastSearchedRangeFilterParams;

    let searchQuery =
      payload.searchQuery !== undefined
        ? payload.searchQuery
        : state.lastSearchedQuery;

    const userConfiguration = rootGetters["authentication/userConfiguration"];

    const {
      history: {
        current: { query },
      },
    } = router;

    let queryFriendlyParams = {};

    if (isInitialLoad && !!Object.keys(query).length) {
      queryFriendlyParams = query;

      booleanFilterParams = Object.keys(queryFriendlyParams)
        .filter(key => keysForInitialBooleanFilterParams.includes(key))
        .filter(key => !!queryFriendlyParams[key])
        .map(key => ({ key }));

      facetFilterParams = Object.keys(queryFriendlyParams)
        .filter(key => keysForInitialFacetFilterParams.includes(key))
        .map(key => ({
          key,
          values: queryFriendlyParams[key].split("+"),
        }));

      searchQuery = query.search;
    } else {
      booleanFilterParams.forEach(
        filter => (queryFriendlyParams[filter.key] = true),
      );

      facetFilterParams.forEach(
        filter => (queryFriendlyParams[filter.key] = filter.values.join("+")),
      );

      if (searchQuery) {
        queryFriendlyParams["search"] = searchQuery;
      }
    }

    if (Object.keys(queryFriendlyParams)) {
      router.push({ query: queryFriendlyParams });
    }

    return axios
      .post(
        `${endpoints.ECOMMERCE}/product/SearchVariantsForProductSheetConfiguration`,
        {
          booleanFilterParams,
          currency: userConfiguration.contextCurrency,
          divisionId,
          facetFilterParams,
          initialBooleanFilterParams,
          initialFacetFilterParams,
          pageNumberZeroBased: page,
          pageSize: pageSize || 18,
          rangeFilterParams,
          searchPhrase: searchQuery,
        },
      )
      .then(({ data }) => {
        commit("SET_PRODUCTS_STATUS", { status: statuses.SUCCESS });
        commit("SET_FILTERS", { filters: data.filters });

        const hasPageChanged = !isEqual(state.pagination.currentPage, page);

        commit("SET_PAGINATION", { pagination: data.pagination });

        const hasBooleanFilterParamsChanged = !isEqual(
          state.lastSearchedBooleanFilterParams,
          booleanFilterParams,
        );
        const hasFacetFilterParamsChanged = !isEqual(
          state.lastSearchedFacetFilterParams,
          facetFilterParams,
        );
        const hasRangeFilterParamsChanged = !isEqual(
          state.lastSearchedRangeFilterParams,
          rangeFilterParams,
        );
        const hasSearchQueryChanged = !isEqual(
          state.lastSearchedQuery,
          searchQuery,
        );

        const shouldAppend =
          hasPageChanged &&
          !hasBooleanFilterParamsChanged &&
          !hasFacetFilterParamsChanged &&
          !hasRangeFilterParamsChanged &&
          !hasSearchQueryChanged;

        commit("SET_PRODUCTS_FOR_PRODUCT_SHEET_CONFIGURATION", {
          append: shouldAppend,
          products: data.products,
        });
        commit("SET_LAST_SEARCHED_QUERY", { query: searchQuery });
        commit("SET_LAST_SEARCHED_BOOLEAN_FILTER_PARAMS", {
          booleanFilterParams,
        });
        commit("SET_LAST_SEARCHED_FACET_FILTER_PARAMS", { facetFilterParams });
        commit("SET_LAST_SEARCHED_RANGE_FILTER_PARAMS", { rangeFilterParams });
      })
      .catch(error => {
        commit("SET_PRODUCTS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_PRODUCTS_FOR_PURCHASE_LIMITATION_CONFIGURATION(
    { commit, state },
    payload,
  ) {
    commit("SET_PRODUCTS_STATUS", { status: statuses.LOADING });

    const { page, pageSize, purchaseLimitationId, searchQuery } = payload;

    return axios
      .post(
        `${endpoints.ECOMMERCE}/product/SearchProductsForPurchaseLimitConfiguration`,
        {
          pageNumberZeroBased: page,
          pageSize: pageSize || 18,
          purchaseLimitationId,
          searchPhrase: searchQuery,
        },
      )
      .then(({ data }) => {
        commit("SET_PRODUCTS_STATUS", { status: statuses.SUCCESS });
        let hasPageChanged = false;

        if (page > 0) {
          hasPageChanged = !isEqual(state.pagination.currentPage, page);
        }

        commit("SET_PAGINATION", { pagination: data.pagination });

        const hasSearchQueryChanged = !isEqual(
          state.lastSearchedQuery,
          searchQuery,
        );

        const shouldAppend = hasPageChanged && !hasSearchQueryChanged;

        commit("SET_PRODUCTS_FOR_PURCHASE_LIMITATION_CONFIGURATION", {
          append: shouldAppend,
          products: data.products,
        });
      })
      .catch(error => {
        commit("SET_PRODUCTS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_PRODUCTS_FOR_WARDROBE_CONFIGURATION({ commit, state }, payload) {
    commit("SET_PRODUCTS_STATUS", { status: statuses.LOADING });

    const { page, pageSize, searchQuery, wardrobeId } = payload;

    return axios
      .post(
        `${endpoints.ECOMMERCE}/product/SearchProductsForWardrobeConfiguration`,
        {
          pageNumberZeroBased: page,
          pageSize: pageSize || 18,
          searchPhrase: searchQuery,
          wardrobeId,
        },
      )
      .then(({ data }) => {
        commit("SET_PRODUCTS_STATUS", { status: statuses.SUCCESS });
        let hasPageChanged = false;

        if (page > 0) {
          hasPageChanged = !isEqual(state.pagination.currentPage, page);
        }

        commit("SET_PAGINATION", { pagination: data.pagination });

        const hasSearchQueryChanged = !isEqual(
          state.lastSearchedQuery,
          searchQuery,
        );

        const shouldAppend = hasPageChanged && !hasSearchQueryChanged;

        commit("SET_PRODUCTS_FOR_WARDROBE_CONFIGURATION", {
          append: shouldAppend,
          products: data.products,
        });
      })
      .catch(error => {
        commit("SET_PRODUCTS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_RETAIL_PRICE_DISCOUNT({ commit }, payload) {
    commit("SET_PRODUCTS_STATUS", { status: statuses.LOADING });

    return new Promise((resolve, reject) => {
      axios
        .get(
          `${endpoints.ECOMMERCE}/price/getCustomerRetailPriceDiscount/${payload.id}`,
        )
        .then(({ data }) => {
          commit("SET_PRODUCTS_STATUS", { status: statuses.SUCCESS });
          commit("SET_RETAIL_PRICE_DISCOUNT", {
            discount: typeof data === "number" ? data : null,
          });
          resolve(data);
        })
        .catch(error => {
          commit("SET_PRODUCTS_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject();
        });
    });
  },
  GET_SUBSTITUTIONS_FOR_PRODUCT({ commit }, payload) {
    commit("SET_PRODUCTS_STATUS", { status: statuses.LOADING });

    const { colorNumber, variantIds } = payload;

    return axios
      .post(`${endpoints.ECOMMERCE}/product/GetSubstitutions`, {
        colorNumber,
        variantIds,
      })
      .then(({ data }) => {
        commit("SET_PRODUCTS_STATUS", { status: statuses.SUCCESS });
        commit("SET_SUBSTITUTION_VARIANTS_FOR_CURRENT_VARIANT", {
          substitutionVariants: data,
        });
      })
      .catch(error => {
        commit("SET_PRODUCTS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_VARIANTS_FOR_PRODUCT_BLOCK({ commit }, payload) {
    commit("SET_PRODUCTS_STATUS", { status: statuses.LOADING });

    return axios
      .post(
        `${endpoints.ECOMMERCE}/product/GetVariantsForProductBlock`,
        payload.variantIds,
      )
      .then(({ data }) => {
        commit("SET_PRODUCTS_STATUS", { status: statuses.SUCCESS });
        commit("SET_VARIANTS_FOR_PRODUCT_BLOCKS", { variants: data });
      })
      .catch(error => {
        commit("SET_PRODUCTS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_VARIANTS_FOR_PRODUCT_SHEET({ commit }, payload) {
    commit("SET_PRODUCTS_STATUS", { status: statuses.LOADING });

    return axios
      .post(
        `${endpoints.ECOMMERCE}/product/GetVariantsForProductSheet`,
        payload.variantIds,
      )
      .then(({ data }) => {
        commit("SET_PRODUCTS_STATUS", { status: statuses.SUCCESS });
        commit("SET_VARIANTS_FOR_PRODUCT_SHEET", {
          variants: data,
        });
      })
      .catch(error => {
        commit("SET_PRODUCTS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  RESET_PRODUCTS({ commit }) {
    commit("SET_PRODUCTS", {
      append: false,
      products: [],
    });
  },
  RESET_PRODUCTS_FOR_PRODUCT_SHEET_CONFIGURATION({ commit }) {
    commit("SET_PRODUCTS_FOR_PRODUCT_SHEET_CONFIGURATION", {
      append: false,
      products: [],
    });
  },
  RESET_SEARCH_PARAMETERS({ commit }) {
    commit("SET_LAST_SEARCHED_QUERY", { query: null });
    commit("SET_LAST_SEARCHED_BOOLEAN_FILTER_PARAMS", {
      booleanFilterParams: [],
    });
    commit("SET_LAST_SEARCHED_FACET_FILTER_PARAMS", { facetFilterParams: [] });
    commit("SET_LAST_SEARCHED_RANGE_FILTER_PARAMS", { rangeFilterParams: [] });
  },
  RESET_SUBSTITUTIONS({ commit }) {
    commit("SET_SUBSTITUTION_VARIANTS_FOR_CURRENT_VARIANT", {
      substitutionVariants: [],
    });
  },
  TOGGLE_PRODUCT_FOR_CONFIGURATION({ commit }, payload) {
    commit("SET_SELECTED_PRODUCT_FOR_CONFIGURATION", payload);
  },
  TOGGLE_PRODUCTS_FOR_CONFIGURATION({ commit }, payload) {
    commit("SET_SELECTED_PRODUCTS_FOR_CONFIGURATION", payload);
  },
  UPDATE_CURRENT_PRODUCT({ commit }, payload) {
    commit("SET_CURRENT_PRODUCT", payload);
  },
  UPDATE_CURRENT_PRODUCT_ID({ commit }, payload) {
    commit("SET_CURRENT_PRODUCT_ID", payload);
  },
  UPDATE_CURRENT_VARIANT_ID({ commit }, payload) {
    commit("SET_CURRENT_VARIANT_ID", payload);
  },
  UPDATE_PRODUCT_PRICES({ commit }, payload) {
    commit("SET_PRODUCTS_STATUS", { status: statuses.SAINVG });

    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoints.ECOMMERCE}/price/UpdateEmployeePrices`, payload)
        .then(({ data }) => {
          commit("SET_PRODUCTS_STATUS", { status: statuses.SUCCESS });
          resolve(data);
        })
        .catch(error => {
          commit("SET_PRODUCTS_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject();
        });
    });
  },
  UPDATE_RETAIL_PRICE_DISCOUNT({ commit }, payload) {
    commit("SET_PRODUCTS_STATUS", { status: statuses.SAINVG });

    return new Promise((resolve, reject) => {
      axios
        .post(
          `${endpoints.ECOMMERCE}/price/UpdateCustomerRetailPriceDiscount`,
          payload,
        )
        .then(({ data }) => {
          commit("SET_PRODUCTS_STATUS", { status: statuses.SUCCESS });
          commit("SET_RETAIL_PRICE_DISCOUNT", {
            discount: typeof data === "number" ? data : null,
          });
          resolve(data);
        })
        .catch(error => {
          commit("SET_PRODUCTS_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject();
        });
    });
  },
  UPDATE_SEARCH_QUERY({ commit }, payload) {
    commit("SET_LAST_SEARCHED_QUERY", payload);
  },
};

const mutations = {
  SET_CURRENT_PRODUCT(state, payload) {
    state.currentProduct = payload.product;
  },
  SET_CURRENT_PRODUCT_ID(state, payload) {
    state.currentProductId = payload.id;
  },
  SET_CURRENT_VARIANT_ID(state, payload) {
    state.currentVariantId = payload.id;
  },
  SET_FILTERS(state, payload) {
    state.filters = payload.filters;
  },
  SET_LAST_SEARCHED_BOOLEAN_FILTER_PARAMS(state, payload) {
    state.lastSearchedBooleanFilterParams = payload.booleanFilterParams;
  },
  SET_LAST_SEARCHED_FACET_FILTER_PARAMS(state, payload) {
    state.lastSearchedFacetFilterParams = payload.facetFilterParams;
  },
  SET_LAST_SEARCHED_QUERY(state, payload) {
    state.lastSearchedQuery = payload.query;
  },
  SET_LAST_SEARCHED_RANGE_FILTER_PARAMS(state, payload) {
    state.lastSearchedRangeFilterParams = payload.rangeFilterParams;
  },
  SET_PAGINATION(state, payload) {
    state.pagination = payload.pagination;
  },
  SET_PRICES_FOR_CONFIGURATION(state, payload) {
    state.productPricesForConfiguration = payload.prices;
  },
  SET_PRODUCTS(state, payload) {
    if (payload.append) {
      state.products = [...state.products, ...payload.products];
      return;
    }

    state.products = payload.products;
    state.lastSearchedQuery = payload.searchQuery;
  },
  SET_PRODUCTS_FOR_PRICE_CONFIGURATION(state, payload) {
    state.productsForPriceConfiguration = payload.products;
  },
  SET_PRODUCTS_FOR_PRODUCT_SHEET_CONFIGURATION(state, payload) {
    if (payload.append) {
      state.productsForProductSheetConfiguration = [
        ...state.productsForProductSheetConfiguration,
        ...payload.products,
      ];
      return;
    }

    state.productsForProductSheetConfiguration = payload.products;
    state.lastSearchedQuery = payload.searchQuery;
  },
  SET_PRODUCTS_FOR_PURCHASE_LIMITATION_CONFIGURATION(state, payload) {
    if (payload.append) {
      state.productsForPurchaseLimitationConfiguration = [
        ...state.productsForPurchaseLimitationConfiguration,
        ...payload.products,
      ];
      return;
    }

    state.productsForPurchaseLimitationConfiguration = payload.products;
  },
  SET_PRODUCTS_FOR_WARDROBE_CONFIGURATION(state, payload) {
    if (payload.append) {
      state.productsForWardrobeConfiguration = [
        ...state.productsForWardrobeConfiguration,
        ...payload.products,
      ];
      return;
    }

    state.productsForWardrobeConfiguration = payload.products;
  },
  SET_PRODUCTS_STATUS(state, payload) {
    state.status = payload.status;
  },
  SET_RETAIL_PRICE_DISCOUNT(state, payload) {
    state.retailPriceDiscount = payload.discount;
  },
  SET_SELECTED_PRODUCT_FOR_CONFIGURATION(state, payload) {
    const { row: product, value } = payload;

    if (value) {
      if (
        state.selectedProductsForPriceConfiguration.some(
          entry => entry.id === product.id,
        )
      ) {
        return;
      }

      state.selectedProductsForPriceConfiguration.push(product);
    } else {
      state.selectedProductsForPriceConfiguration = state.selectedProductsForPriceConfiguration.filter(
        selectedProduct => selectedProduct.id !== product.id,
      );
    }
  },
  SET_SELECTED_PRODUCTS_FOR_CONFIGURATION(state, payload) {
    const { rows: products, value } = payload;

    if (value) {
      const identifiers = new Set(
        state.selectedProductsForPriceConfiguration.map(
          selectedProduct => selectedProduct.id,
        ),
      );

      state.selectedProductsForPriceConfiguration = [
        ...state.selectedProductsForPriceConfiguration,
        ...products
          .filter(product => !product.disableSelection)
          .filter(product => !identifiers.has(product.id)),
      ];
    } else {
      state.selectedProductsForPriceConfiguration = state.selectedProductsForPriceConfiguration.filter(
        selectedProduct =>
          !products.some(entry => entry.id === selectedProduct.id),
      );
    }
  },
  SET_SUBSTITUTION_VARIANTS_FOR_CURRENT_VARIANT(state, payload) {
    state.substitutionVariantsForCurrentVariant = payload.substitutionVariants;
  },
  SET_VARIANTS_FOR_PRODUCT_BLOCKS(state, payload) {
    const filteredVariants = state.variantsForProductBlocks.filter(
      currentVariant =>
        !payload.variants.some(
          variant => variant.variantId === currentVariant.variantId,
        ),
    );

    state.variantsForProductBlocks = [...filteredVariants, ...payload.variants];
  },
  SET_VARIANTS_FOR_PRODUCT_SHEET(state, payload) {
    const filteredVariants = state.variantsForProductSheet.filter(
      currentVariant =>
        !payload.variants.some(
          variant => variant.variantId === currentVariant.variantId,
        ),
    );

    state.variantsForProductSheet = [...filteredVariants, ...payload.variants];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
