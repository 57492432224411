<template>
  <div>
    <ToggleSwitch
      v-model="internalCurrentCatalogue.shouldUseCurrency"
      :label="getDictionaryEntry('Common.Labels.ShowCurrency')"
      class="basket__switch"
      @input="updateCatalogue"
    />
    <div
      ref="currency"
      class="basket__actions"
      :class="{ 'basket__actions--hide-overflow': hideOverflow }"
    >
      <ol class="basket__actions-list">
        <li class="basket__actions-item basket__actions-item--currency">
          <div class="basket__actions-content">
            <div class="basket__action">
              <Dropdown
                v-model.trim="internalCurrentCatalogue.currency"
                :allow-empty="false"
                :clear-on-select="true"
                :close-on-select="true"
                :label-text="getDictionaryEntry('Common.Labels.Currency')"
                :multiple="false"
                :options="currencies"
                :placeholder="
                  getDictionaryEntry('Common.Placeholders.SelectCurrency')
                "
                required
                @select="updateCatalogue($event)"
              />
            </div>
          </div>
        </li>
        <li class="basket__actions-item basket__actions-item--discount">
          <div class="basket__actions-content">
            <Input
              v-model.trim="internalCurrentCatalogue.globalDiscountPercent"
              :label="getDictionaryEntry('Common.Labels.Discount')"
              placeholder="0"
              suffix="%"
              type="number"
              @blur="updateCatalogue"
            />
          </div>
        </li>
      </ol>
    </div>
    <transition-group
      v-if="internalCurrentCatalogue"
      tag="ul"
      class="basket__entries"
      name="fade-slide"
      appear=""
    >
      <li
        v-for="(basketLine, index) in internalCurrentCatalogue.basketLines"
        :key="basketLine.variantId"
        class="basket__entry"
        :style="{ '--index': index }"
      >
        <article v-if="variants[basketLine.variantId]" class="product">
          <div
            v-if="doesVariantImageExist(variants[basketLine.variantId])"
            class="product__image-container"
          >
            <ProductImage
              :variant="variants[basketLine.variantId]"
              class="product__image"
            />
          </div>
          <div class="product__text">
            <Stack v-if="variants[basketLine.variantId].productId">
              <div class="product__number">
                {{ getDictionaryEntry("Product.ProductNumber") }}:
                {{ variants[basketLine.variantId].productId }}
              </div>
            </Stack>
            <Stack
              v-if="variants[basketLine.variantId].productName"
              :quarter="true"
            >
              <h1 class="product__title">
                {{ variants[basketLine.variantId].productName }}
              </h1>
            </Stack>
            <Stack
              v-if="
                variants[basketLine.variantId].variantName ||
                  variants[basketLine.variantId].variantId
              "
              :quarter="true"
            >
              <div
                v-if="variants[basketLine.variantId].variantName"
                class="product__information"
              >
                {{ variants[basketLine.variantId].variantName }}
              </div>
              <div v-if="basketLine.variantId" class="product__information">
                {{ basketLine.variantId }}
              </div>
            </Stack>
          </div>
          <div class="product__actions">
            <Input
              v-if="internalCurrentCatalogue.shouldUseCurrency"
              v-model.trim="basketLine.discountPercent"
              :hide-label="true"
              :label="getDictionaryEntry('Common.Labels.Discount')"
              :placeholder="
                internalCurrentCatalogue &&
                internalCurrentCatalogue.globalDiscountPercent
                  ? internalCurrentCatalogue.globalDiscountPercent
                  : 0
              "
              suffix="%"
              type="number"
              @blur="updateVariantDiscount(basketLine)"
            />
            <button
              class="product__remove"
              @click="
                REMOVE_VARIANT_FROM_CURRENT_CATALOGUE({
                  variantId: basketLine.variantId,
                })
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="product__remove-icon"
                viewBox="0 0 24 24"
              >
                <path
                  d="M3 6h18M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2M10 11v6M14 11v6"
                />
              </svg>
            </button>
          </div>
        </article>
      </li>
    </transition-group>
  </div>
</template>

<script>
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
import { mapActions, mapGetters } from "vuex";
import Dropdown from "@/components/Dropdown";
import Input from "@/components/Input";
import ProductImage from "@/components/ProductImage";
import Stack from "@/components/Stack";
import ToggleSwitch from "@/components/ToggleSwitch";
import { slideDown, slideUp } from "@/helpers/slide";
import { statuses } from "@/helpers/statuses";

export default {
  name: "CataloguesBasket",
  components: {
    Dropdown,
    Input,
    ProductImage,
    Stack,
    ToggleSwitch,
  },
  data() {
    return {
      hideOverflow: false,
      internalCurrentCatalogue: {},
    };
  },
  computed: {
    ...mapGetters("catalogues", ["currentCatalogue", "statusOfCatalogues"]),
    ...mapGetters("products", ["variantsForProductSheetByIds"]),
    ...mapGetters("siteSettings", ["currencies"]),
    isSaving() {
      return this.statusOfCatalogues === statuses.SAVING;
    },
    variants() {
      const { internalCurrentCatalogue, variantsForProductSheetByIds } = this;

      if (
        !internalCurrentCatalogue ||
        !internalCurrentCatalogue.basketLines ||
        !internalCurrentCatalogue.basketLines.length
      ) {
        return [];
      }

      const variantsForProductSheet = variantsForProductSheetByIds(
        internalCurrentCatalogue.basketLines.map(
          basketLine => basketLine.variantId,
        ),
      );

      const variantDictionary = {};

      variantsForProductSheet.forEach(variant => {
        variantDictionary[variant.variantId] = variant;
      });

      return variantDictionary;
    },
  },
  watch: {
    currentCatalogue: {
      handler: function(newValue) {
        if (newValue && !isEqual(newValue, this.internalCurrentCatalogue)) {
          this.internalCurrentCatalogue = cloneDeep(newValue);
        }
      },
      deep: true,
    },
    "currentCatalogue.basketLines"(newValue, oldValue) {
      if (!newValue || !newValue.length || isEqual(newValue, oldValue)) return;

      this.GET_VARIANTS_FOR_PRODUCT_SHEET({
        variantIds: newValue.map(basketLine => basketLine.variantId),
      });
    },
    internalCurrentCatalogue: {
      handler: function(newValue) {
        this.$emit("catalogue-update", newValue);
      },
      deep: true,
    },
    "internalCurrentCatalogue.shouldUseCurrency": {
      handler: function(newValue, oldValue) {
        if (newValue !== oldValue) {
          if (newValue) {
            slideDown(this.$refs.currency, this.setOverflowToVisible);
          } else {
            this.setOverflowToHidden();
            slideUp(this.$refs.currency);
          }
        }
      },
    },
  },
  created() {
    const { GET_VARIANTS_FOR_PRODUCT_SHEET, currentCatalogue } = this;

    if (
      !currentCatalogue ||
      !currentCatalogue.basketLines ||
      !currentCatalogue.basketLines.length
    ) {
      return;
    }

    this.internalCurrentCatalogue = cloneDeep(currentCatalogue);

    GET_VARIANTS_FOR_PRODUCT_SHEET({
      variantIds: currentCatalogue.basketLines.map(
        basketLine => basketLine.variantId,
      ),
    });
  },
  methods: {
    ...mapActions("catalogues", [
      "ADD_DISCOUNT_TO_VARIANT_IN_CATALOGUE",
      "REMOVE_VARIANT_FROM_CURRENT_CATALOGUE",
      "UPDATE_CATALOGUE",
    ]),
    ...mapActions("products", ["GET_VARIANTS_FOR_PRODUCT_SHEET"]),
    getVariant(id) {
      if (!id) return null;

      return this.variants.find(variant => variant.variantId === id);
    },
    setOverflowToHidden() {
      this.hideOverflow = true;
    },
    setOverflowToVisible() {
      this.hideOverflow = false;
    },
    updateCatalogue(attributes) {
      const {
        currency,
        globalDiscountPercent,
        shouldUseCurrency,
      } = this.internalCurrentCatalogue;

      let selectedCurrency;

      if (attributes && attributes.value) {
        selectedCurrency = attributes.value;
      } else if (Array.isArray(currency)) {
        selectedCurrency = null;
      } else {
        selectedCurrency = currency;
      }

      this.UPDATE_CATALOGUE({
        currency: selectedCurrency,
        discountPercent: globalDiscountPercent,
        shouldUseCurrency,
      });
    },
    updateVariantDiscount(basketLine) {
      const { discountPercent, variantId } = basketLine;

      this.ADD_DISCOUNT_TO_VARIANT_IN_CATALOGUE({
        discountPercent,
        variantId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$sidebar-width-wide: 43rem;

.basket {
  &__action {
    cursor: pointer;
  }

  &__actions {
    display: none;

    &--hide-overflow {
      overflow: hidden;
    }

    &-content {
      padding: var(--spacing-sidebar);

      @media (min-width: 64rem) {
        padding: var(--spacing-sidebar-large);
      }
    }

    &-item {
      border: 2px solid var(--color-sidebar-border);
      border-width: 2px 0 3px;
      flex: 1 1 auto;

      &:last-child {
        border-left-width: 3px;
      }

      &--currency {
        cursor: pointer;
      }

      &--discount {
        max-width: 8rem;
      }
    }

    &-list {
      @include reset-list();

      display: flex;
      flex-flow: row wrap;

      @media (min-width: $sidebar-width-wide) {
        flex-flow: wrap;
      }
    }
  }

  &__entries {
    @include reset-list();

    margin-top: var(--spacing-stack);
  }

  &__entry {
    padding: 0 var(--spacing-sidebar);

    @media (min-width: 64rem) {
      padding: 0 var(--spacing-sidebar-large);
    }

    &:not(:first-child) {
      border-top: 1px solid var(--color-table-border);
    }
  }

  &__switch {
    border-bottom: 1px solid var(--color-sidebar-border);
    padding: calc(var(--spacing-sidebar) * 0.5) var(--spacing-sidebar);

    @media (min-width: 64rem) {
      padding: calc(var(--spacing-sidebar-large) * 0.5)
        var(--spacing-sidebar-large);
    }
  }
}

.fade-slide {
  &-enter-active {
    transition-delay: calc(50ms * var(--index)) !important;
  }

  &-enter-active,
  &-leave-active {
    transition: opacity 300ms ease, transform 300ms ease;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
    transform: translateY(1rem);
  }
}

.product {
  align-items: center;
  color: inherit;
  display: flex;
  flex-flow: row wrap;
  font-size: 0.875rem;
  font-weight: normal;
  justify-content: space-between;
  padding: calc(var(--spacing-stack)) 0;

  &__actions {
    display: flex;
    flex: 0 0 100%;
    justify-content: flex-end;
    margin-top: var(--spacing-sidebar);

    @media (min-width: $sidebar-width-wide) {
      flex: 0 0 8rem;
      margin-top: 0;
    }
  }

  &__image {
    display: block;
    height: 100%;
    left: 50%;
    object-fit: contain;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    &-container {
      background-color: var(--color-products-image-background);
      flex: 0 0 100%;
      margin-bottom: calc(var(--spacing-stack) * 0.5);
      position: relative;

      @media (min-width: $sidebar-width-wide) {
        flex: 0 0 5rem;
        margin: 0;
      }

      &::before {
        content: "";
        display: block;
        padding-top: (1 / 1) * 100%;
        width: 100%;
      }
    }
  }

  &__information {
    line-height: 1.3;
  }

  &__remove {
    @include reset-button();

    align-items: center;
    border-radius: 0.25rem;
    border: 1px solid var(--color-input-border);
    display: flex;
    flex: 0 0 2.75rem;
    height: 2.9375rem;
    justify-content: center;
    margin-left: calc(var(--spacing-stack) * 0.25);
    transition: border-color 300ms ease;
    width: 2.75rem;

    &:focus,
    &:hover {
      border-color: var(--color-input-border-active);
    }

    &-icon {
      fill: none;
      height: var(--size-basket-remove-icon);
      opacity: 0.75;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 2;
      stroke: var(--color-text-primary);
      transition: opacity 300ms ease;
      width: var(--size-basket-remove-icon);

      &:hover {
        opacity: 1;
      }
    }
  }

  &__number {
    font-size: 0.8125rem;
    font-weight: 600;
    opacity: 0.3;
  }

  &__text {
    flex: 0 0 100%;

    @media (min-width: $sidebar-width-wide) {
      flex: 1 1 auto;
    }

    &:not(:first-child) {
      @media (min-width: $sidebar-width-wide) {
        margin: 0 calc(var(--spacing-stack) * 0.75);
      }
    }
  }

  &__title {
    font-size: 1rem;
    margin: 0;
  }
}
</style>
