var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:(`brand-${_vm.divisionId}`,
    {
      'has-service-bar': _vm.shouldShowServiceBar,
      'is-locked': _vm.shouldLockScroll,
    }),attrs:{"id":"app"}},[_c('transition',{attrs:{"name":"slide-in-from-top"}},[(
        _vm.brand && !_vm.isWaitingForSlidingOverlay && !_vm.shouldUseSidebarNavigation
      )?_c('div',{staticClass:"header-container"},[(_vm.shouldShowServiceBar)?_c('ServiceBar'):_vm._e(),_c('Header')],1):_vm._e()]),_c('transition',{attrs:{"name":"slide-in-from-left"}},[(!_vm.isWaitingForSlidingOverlay && _vm.shouldUseSidebarNavigation)?_c('NavigationSidebar'):_vm._e()],1),_c('Breadcrumbs'),_c('main',{staticClass:"main",style:(_vm.shouldLockScroll
        ? `margin-top: -${_vm.scrollPositionToRestore}px`
        : 'margin-top: 0')},[_c('PageTransition',{on:{"updateWaitingState":_vm.setWaitingState}},[(_vm.isRouteLoginRelated || _vm.isRequiredDataReady)?_c('router-view'):_vm._e()],1)],1),_c('UserConfigurator',{attrs:{"default-language":_vm.preferredBrowserLanguage,"visible":_vm.isUserConfiguratorOpen}}),_c('BackToTop'),_c('Alerts'),(_vm.shouldShowProductDetails)?_c('Product'):_vm._e(),(_vm.shouldShowQuickBuy)?_c('QuickBuy'):_vm._e(),_c('Help')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }